<template>
  <div class="align-self-end" v-if="history && history.length">
    <v-card
      flat
      color="transparent"
      class="mt-4"
    >
      <v-card-text class="d-flex flex-column align-center justify-center">
        Últimas Validações:

        <v-simple-table class="history-table mt-2">
          <template v-slot:default>
            <tbody>
              <tr v-for="(data, index) in history" :key="index">
                <td>
                  {{ $moment(getDate(data)).format('HH:mm:ss') }}
                </td>
                <td>
                  {{data.code}}
                </td>
                <td :class="getStatusColor(data)">
                  {{ $t(getStatusText(data)) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div
          v-show="! history.length"
          class="mt-2"
        >
          {{ $t('reading_none') }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'ComponentsCommonVoucherHistory',

  data: () => ({
    //
  }),

  methods: {
    getStatusColor(voucher) {
      if (voucher.success !== null) {
        return 'green--text'
      } else {
        return 'yellow--text'
      }
    },
    getStatusText(voucher) {
      if (voucher.success !== null) {
        return 'abbrev_reading_success'
      } else if (voucher.already_read !== null) {
        return 'abbrev_reading_error_voucher_already_read'
      } else if (voucher.forbidden_in_section !== null) {
        return 'abbrev_reading_error_voucher_forbidden_in_section'
      } else if (voucher.not_found_invalid !== null) {
        return 'abbrev_reading_error_voucher_not_found_or_invalid'
      } else {
        return 'abbrev_reading_error_voucher_forbidden'
      }
    },
    getDate(voucher) {
      return voucher.success ?? voucher.already_read ?? voucher.forbidden_in_section ?? voucher.forbidden ?? voucher.not_found_invalid
    }
  },

  computed: {
    history() {
      if (!this.currentEvent) return [];

      const found = this.$store.state.vouchers.history[this.currentEvent.id]

      if (found) {
        return found.sort((a, b) => {
          
          if (this.getDate(a) > this.getDate(b)) return 1
          return -1
        }).reverse().slice(0, 2)
      }

      return []
    },

    currentSections() {
      return this.$store.state.sectors.selected
    },

    currentEvent() {
      return this.$store.state.events.selected
    }
  }
}
</script>

<style>
  .history-table {
    background-color: transparent !important;
  }
  
  .history-table table,
  .history-table td,
  .history-table tr {
    background-color: transparent !important;
    border: 1px dashed #ccc;
    border-collapse: collapse;
  }

  .history-table td {
    height: 30px !important;
  }
</style>

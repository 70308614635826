import http from '../plugins/axios';

export default {
  async login(client_id, user, password) {
    const response = await http.post('/api/client/auth', {
      client_id: client_id,
      user: user,
      password: password
    })
    
    return response.data.token;
  }
}
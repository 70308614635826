<template>
  <layout-default :drawer-items="drawer.items">
    <div
      class="views offline list"
    >
      <v-container>
        <v-row no-gutters>
          <layouts-partials-info-header :title="'cargo'" :showSections="false" :showEvent="false"/>
          <v-col cols="12" class="mt-4">
            <v-row>
              <v-col>
                <v-card v-for="event in events" :key="event.id" class="mb-4">
                  <v-card-title class="justify-center">{{ event.title }}</v-card-title>
                  <v-card-subtitle class="text-center">{{ event.type }}</v-card-subtitle>
                  <v-simple-table class="mt-6">
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td colspan="3" class="text-left font-weight-bold">
                            {{ $t('storage') }}
                          </td>
                          <td colspan="1" class="text-right font-weight-bold">
                            {{ calculateStorage(event) }} MBs
                          </td>
                        </tr>
                        <tr v-if="$store.state.vouchers.history[event.id]">
                          <td colspan="3" class="text-left">
                            {{ $t('readings') }}
                          </td>
                          <td colspan="1" class="text-right">
                            {{ $store.state.vouchers.history[event.id].length }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-card-actions class="justify-center">
                    <v-btn color="red" @click="removePrepare(event)">
                      Apagar dados locais
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog
        v-model="dialog"
        persistent
        width="320"
      >
        <v-card>
          <v-card-title
            class="text-h5"
          >
            {{ $t('event_data_deletion') }}
          </v-card-title>
          <v-card-text
            v-html="$t('event_data_deletion_warn')"
          />
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              color="grey"
              :loading="loading.remove"
              @click="dialog = false"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              text
              color="red darken-1"
              :loading="loading.remove"
              @click="remove()"
            >
              {{ $t('remove') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/Default'
import LayoutsPartialsInfoHeader from '@/layouts/partials/InfoHeader'

export default {
  name: `OfflineList`,

  components: {
    LayoutDefault,
    LayoutsPartialsInfoHeader
  },
  
  data: () => ({
    drawer: {
      items: [
        {
          icon: 'fa-solid fa-shield-halved',
          title: 'producers',
          toRouteName: 'producer_index'
        },
        {
          icon: 'fa-solid fa-calendar-days',
          title: 'events',
          toRouteName: 'event_index'
        },
        {
          icon: 'mdi-barcode-scan',
          title: 'reading',
          toRouteName: 'index'
        },
        {
          icon: 'fa-solid fa-grid-2',
          title: 'sections',
          toRouteName: 'offline_section'
        },
        {
          icon: 'mdi-chart-line',
          title: 'reports',
          toRouteName: 'report'
        },
        {
          icon: 'mdi-database',
          title: 'cargo',
          items: [
            {
              icon: 'mdi-database-cog',
              title: 'manage',
              toRouteName: 'offline_manage'
            }
          ]
        }
      ]
    },
    loading: {
      remove: false
    },
    dialog: false,
    eventBeingRemoved: null,
    headers: [
      {
        text: 'events',
        text_translation: 'events',
        sortable: false,
        value: 'title',
      },
      {
        text: 'producers',
        text_translation: 'producers',
        sortable: false,
        value: 'data.producer.title',
      },
      {
        text: 'clients',
        text_translation: 'client_application',
        align: 'start',
        sortable: false,
        value: 'data.client.title',
      },
      {
        text: 'actions',
        align: 'end',
        sortable: false,
        value: 'actions',
      }
    ],
    headerProps: {
      sortByText: ''
    }
  }),
  
  methods: {
    removePrepare(event) {
      this.eventBeingRemoved = event

      this.dialog = true
    },

    remove() {
      this.$store.commit("vouchers/clear", { event: this.eventBeingRemoved })
      this.$store.commit("sectors/clear");
      this.$store.commit("reading/setActiveModule", "");
      this.$forceUpdate()
      this.$router.push({
        name: 'event_index'
      })
      this.dialog = false
    },

    calculateStorage(event) {
      const vouchers = this.$store.state.vouchers.data[event.id];

      if (!vouchers) return "0";

      const size = new TextEncoder().encode(JSON.stringify(vouchers[0])).length / 1024 / 1024;
      return parseFloat(size * vouchers.length).toFixed(2);
    }
  },

  computed: {
    events() {
      const withVouchers = Object.keys(this.$store.state.vouchers.data); 
      return this.$store.state.events.data.filter((event) => withVouchers.includes(event.id));
    },
  }
}
</script>
import moment from 'moment'

export default {
  getClientsProducersEventsVouchers() {
    return JSON.parse(localStorage.getItem('clients_producers_events_vouchers')) || []
  },

  getClientsProducersEventsVouchersFiltered() {
    const selected = this.getSelected()

    return this.getClientsProducersEventsVouchers().find(item => String(item.client) === String(selected.client.id) && String(item.producer) === String(selected.producer.id) && String(item.event) === String(selected.event.id))
  },

  setClientsProducersEventsVouchers(vouchers) {
    const eventsVouchers = this.getClientsProducersEventsVouchers()

    const selected = this.getSelected()

    let target = eventsVouchers.find(item => String(item.client) === String(selected.client.id) && String(item.producer) === String(selected.producer.id) && String(item.event) === String(selected.event.id))

    if (! target) {
      target = {
        client: selected.client.id,
        producer: selected.producer.id,
        event: selected.event.id,
        data: {
          client: selected.client,
          producer: selected.producer,
          event: selected.event
        },
        vouchers: []
      }

      eventsVouchers.push(target)
    }

    let imported = 0

    const existent = new Set(target.vouchers.map((v) => String(v.data)))

    vouchers.map(voucher => {
      if (!existent.has(String(voucher.data))) {
        imported++
        target.vouchers.push(voucher)
        existent.add(String(voucher.data))
      }
    })

    localStorage.setItem('clients_producers_events_vouchers', JSON.stringify(eventsVouchers))

    return imported
  },

  async voucherRead(data) {
    try {
      const eventsVouchers = this.getClientsProducersEventsVouchers()

      const selected = this.getSelected()

      const event = eventsVouchers.find(item => String(item.client) === String(selected.client.id) && String(item.producer) === String(selected.producer.id) && String(item.event) === String(selected.event.id))

      if (! event || ! event.vouchers || ! event.vouchers.length) {
        return
      }

      const sections = selected.sections.map(item => item.id)

      let error = {
        response: {
          status: 400,
          checkin_at: null,
        }
      }

      const voucher = event.vouchers.find(item => {
        const dataCondition = String(item.data) === String(data)
        const documentCondition = item.holder.document !== null && item.holder.document !== "" && String(item.holder.document) === String(data) && !item.checkin_at

        return dataCondition || documentCondition
      })

      if(! voucher) {
        // voucher does not exists

        return new Promise((resolve, reject) => {
          error.response.status = 404

          reject(error)
        })
      } else if(voucher.checkin_at) {
        // voucher already checkin

        return new Promise((resolve, reject) => {
          error.response.status = 422
          error.response.checkin_at = voucher.checkin_at

          reject(error)
        })
      } else if (voucher.sections === null) {
        // voucher not allowed in any section e.g. access denied
        return new Promise((resolve, reject) => {
          error.response.status = 403

          reject(error)
        })
      } else if (! voucher.checkin_at && ! voucher.sections.find(item => sections.includes(item))) {
        // voucher not in required sections

        return new Promise((resolve, reject) => {
          error.response.status = 406

          reject(error)
        })
      }

      return new Promise((resolve) => {
        // voucher is ok

        voucher.checkin_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

        localStorage.setItem('clients_producers_events_vouchers', JSON.stringify(eventsVouchers))

        resolve(voucher)
      })
    } catch (error) {
      return new Promise((resolve, reject) => {
        reject({
          response: {
            status: 500,
            checkin_at: null,
          }
        })
      })
    }

  }
}

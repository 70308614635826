const state = {
    visible: false,
    message: "",
    timeout: 10000,
    color: "",
}

const mutations = {
    show(_state, { message, timeout, color } ) {
        _state.message = typeof message === "string" ? message.toUpperCase() : "";
        _state.timeout = timeout || 10000;
        _state.color = color;
        _state.visible = true;
    },

    hide(_state) {
        _state.visible = false;
        _state.color = "";
        _state.message = "";
    },
};

export const store = {
    namespaced: true,
    state,
    mutations,
};
  
export default {
  getClients() {
    return this.state.clients.length ? this.state.clients : null
    || JSON.parse(localStorage.getItem('clients'))
    || []
  },

  setClients(clients) {
    localStorage.setItem('clients', JSON.stringify(clients))

    this.state.clients = JSON.parse(localStorage.getItem('clients'))
  }
}

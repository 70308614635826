import axios from '@/plugins/axios'

async function saveAppLogs(stack) {
    return await axios.post(
        "/api/log",
        { logs: stack }, 
        { 
            headers: {
                'Http-Load': 'false'
            } 
        }
    );
}

export default saveAppLogs;

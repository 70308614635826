export default {
    getUser() {
      return this.state.user.length ? this.state.user : null
      || JSON.parse(localStorage.getItem('user'))
      || []
    },
  
    setUser(user) {
      localStorage.setItem('user', JSON.stringify(user))
  
      this.state.user = JSON.parse(localStorage.getItem('user'))
    }
  }
  
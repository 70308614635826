import ClientService from '../../services/ClientService';

const state = {
  data: [],
  selected: null,
}

const mutations = {
  set(_state, clients) {
    _state.data = clients;
  },
  select(_state, client) {
    _state.selected = client;
  },
  clear(_state) {
    _state.data = [];
    _state.selected = null;
  }
}

const actions = {
  async fetch(context) {
    try {
      const clients = await ClientService.index()
      context.commit("set", clients)
    } catch (error) {
      context.commit(
        'snackbar/show', 
        {
          message: 'error_reading_clients',
          timeout: 5000,
          color: 'error'
        },
        { root: true }
      )
    }
  }
}

export const store = {
  namespaced: true,
  state,
  mutations,
  actions
}
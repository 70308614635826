<template>
  <div
    class="components module section"
  >
    <v-form
      ref="components_module_section_form"
      v-model="form.valid"
      lazy-validation
    >
      <v-row>
        <v-col>
          <v-select
            v-model="form.fields.section.value"
            chips
            deletable-chips
            multiple
            outlined
            small-chips
            hide-details="auto"
            item-text="title"
            item-value="id"
            :items="sections"
            :label="$t('sections_choose')"
            :menu-props="{
              closeOnClick: true,
              closeOnContentClick: false
            }"
            :no-data-text="$t('no_data')"
            :rules="[form.fields.section.rules.required]"
          >
            <template v-slot:message="slotProps">
              {{ $t(slotProps.message) }}
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            block
            color="primary"
            x-large
            @click="select()"
          >
            {{ $t('choose') }}
          </v-btn>
          <v-btn
            block
            x-large
            color="secondary"
            @click="$router.push('/')"
            class="mt-6"
          >
            {{ $t('return') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: `ComponentsModuleSection`,

  props: {
    redirectOnSave: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    form: {
      valid: false,
      fields: {
        section: {
          rules: {
            required: value => !! value.length || 'sections_error_choose_at_least'
          }
        }
      }
    }
  }),

  mounted() {
    this.setCurrentSectors()
  },

  methods: {
    setCurrentSectors() {
      let sections = this.$store.state.sectors.selected

      if (sections && sections.length) {
        sections = sections.map(section => section.id)

        this.form.fields.section.value = sections
      }
    },

    select() {
      if (!this.$refs.components_module_section_form.validate()) {
        return
      }

      const event = this.$store.state.events.selected

      let sections = []

      if (event && event.sections) {
        this.form.fields.section.value.map(id => {
          const section = event.sections.find(item => String(item.id) === String(id))

          if (section) {
            sections.push(section)
          }
        })
      }

      this.$store.commit("sectors/select", sections)

      this.$router.push(this.redirectOnSave)
    }
  },

  computed: {
    sections() {
      const event = this.$store.state.events.selected

      const vouchers = this.$store.state.vouchers.data[event.id]

      const sectionsWithAtleastOneVoucher = new Set(vouchers.map(v => v.sections).flat())

      const sections = event.sections.filter(s => sectionsWithAtleastOneVoucher.has(s.id))

      if (sections) {
        return sections.toSorted((a, b) => {
          if (a.title > b.title) return 1
          else return -1
        })
      }

      return []
    }
  }
}
</script>

<style lang="scss">
.components {
  &.module {
    &.section {
      .v-select__selections {
        margin-top: 4px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .components {
    &.module {
      &.section {
        //
      }
    }
  }
}
</style>

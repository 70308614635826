
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from '@/locales/locales'
import store from '@/store/index'

Vue.use(VueI18n)

export default new VueI18n({
  fallbackLocale: 'en',
  locale: store.state.i18n.locale,
  messages: locales,
  numberFormats: {
    'pt-BR': {
      currency: {
        style: 'currency',
        currency: 'BRL',
      }
    },
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD',
      }
    }
  },
  silentFallbackWarn: true
})

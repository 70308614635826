export default {
  getHttp() {
    return this.state.http
  },

  setHttp(value) {
    this.state.http = value
  },

  getHttpLoading() {
    return this.state.http.loading ? true : false
  },

  increaseHttpLoading() {
    this.state.http.loading = this.state.http.loading + 1
  },

  decreaseHttpLoading() {
    this.state.http.loading = Math.max(this.state.http.loading - 1, 0)
  }
}

export default {
  'pt-BR': {
    _abbrev: 'BRA',
    _icon: 'br',
    _name: 'Português',
    abbrev_reading_error_voucher_already_read: 'Duplicado',
    abbrev_reading_error_voucher_forbidden_in_section: 'Incorreto',
    abbrev_reading_error_voucher_forbidden: 'Negado',
    abbrev_reading_error_voucher_not_found_or_invalid: 'Negado',
    abbrev_reading_success: 'Liberado',
    application_subtitle: 'Controle de Acesso',
    authentication: 'Autenticação',
    average_reading_time: 'Tempo médio por leitura',
    cancel: 'Cancelar',
    cargo: 'Dados locais',
    choose: 'Avançar',
    client_application: 'Plataforma',
    clients: 'Clientes | Cliente',
    configurations: 'Configurações',
    disconnected: 'Desconectado',
    error_invalid_url: 'URL inválida',
    error_session_expired: 'Sessão expirada',
    event_date: 'Data do evento',
    event_ending_hour: 'Fechamento dos portões',
    event_opening_hour: 'Abertura dos portões',
    event_start_hour: 'Horário do evento',
    events_active: 'Eventos ativos',
    events_choose: 'Selecione um evento',
    events_error_cant_finish: 'Não foi possível finalizar/exportar o evento',
    events_finish_warn: 'Deseja realmente finalizar este evento?<br /><br />Esta ação exporta os dados do evento e o marca como finalizado.',
    events_finish: 'Finalizar evento',
    events_finished_success: 'Evento finalizado com sucesso!',
    events_finished: 'Eventos finalizados',
    event_data_deletion_warn: 'Deseja realmente excluir os dados locais deste evento (somente neste dispositivo)?<br /><br />Esta ação irá limpar os códigos e leituras desse dispositivo de forma irreversível.',
    event_data_deletion: 'Apagar dados locais do evento?',
    events: 'Eventos | Evento',
    executions: 'Execuções | Execução',
    finish: 'Finalizar',
    greetings: 'Olá',
    invalid_credentials: 'Credenciais inválidas',
    login: 'Entrar',
    logout: 'Sair',
    logs: 'Monitoramento',
    manage: 'Gerenciar',
    message: 'Mensagem',
    module_client_error_undefined: 'Nenhum cliente/aplicação selecionado/ativo no momento',
    module_event_error_undefined: 'Nenhum evento selecionado/ativo no momento',
    module_producer_error_undefined: 'Nenhuma produtora selecionada/ativa no momento',
    network_error_invalid_or_unreachable_address: 'Endereço inválido ou inalcançável',
    network_error_point_address: 'Aponte o endereço de rede',
    network_info_undefined: 'Defina o endereço de rede antes de efetuar leituras',
    network_local_server: 'Servidor de leitura local',
    network: 'Em rede',
    no_data: 'Nenhum registro disponível',
    offline: 'Off-line',
    online: 'On-line',
    password: 'Senha',
    producers_choose: 'Cliente',
    producers: 'Clientes | Cliente',
    reading_data: 'Dados de leitura',
    reading_error_no_connection: 'Dispositivo sem conexão',
    reading_error_voucher_already_read: 'Acesso duplicado',
    reading_error_voucher_forbidden_in_section: 'Setor incorreto',
    reading_error_voucher_forbidden: 'Acesso negado',
    reading_error_voucher_not_found_or_invalid: 'Acesso negado',
    reading_last_success: 'Últimas leituras com sucesso',
    reading_mode: 'Modo de leitura',
    reading_none: 'Nenhuma leitura efetuada',
    reading_success: 'Acesso liberado',
    reading_total_success: 'Total de leituras com sucesso',
    reading: 'Leitura',
    readings: 'Leituras',
    readings_by_sections: 'Leituras por setores',
    readings_by_status: 'Leituras por status',
    readings_by_types: 'Leituras por tipo',
    remove: 'Excluir',
    report_reading_error_voucher_already_read: 'Códigos lidos em duplicidade',
    report_reading_error_voucher_forbidden_in_section: 'Códigos lidos em setor incorreto',
    report_reading_error_voucher_forbidden: 'Códigos inválidos',
    report_reading_error_voucher_not_found_or_invalid: 'Códigos inválidos',
    report_reading_success: 'Códigos lidos com sucesso',
    report_subtitle: 'Relatório de Acesso',
    reports: 'Relatórios | Relatório',
    return: 'Voltar',
    save: 'Salvar',
    sections_choose: 'Selecione um setor',
    sections_error_choose_at_least: 'Selecione no mínimo 1 setor',
    sections_in_use: 'Setores em uso',
    sections_info_undefined: 'Defina os setores antes de efetuar letiuras',
    sections: 'Setores',
    sort_by: 'Ordenar por',
    stat: 'Estatística',
    storage: 'Armazenamento',
    timestamp: 'Horário',
    totals: 'Totais | Total',
    user: 'Login',
    validation_producers_select: 'Escolha um cliente',
    value: 'Valor',
    version: 'Versão',
  },
}

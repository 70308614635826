export default {
  getClientsProducers() {
    return this.state.clientsProducers.length ? this.state.clientsProducers : null
    || JSON.parse(localStorage.getItem('clients_producers'))
    || []
  },

  getClientsProducersFiltered() {
    return this.getClientsProducers().filter(item => String(item.client) === String(this.getSelectedByKey('client').id))
  },

  setClientsProducers(producers) {
    let clientProducers = this.getClientsProducers().filter(item => String(item.client) !== String(this.getSelectedByKey('client').id))

    clientProducers = [
      ...clientProducers,
      ...producers
    ]

    localStorage.setItem('clients_producers', JSON.stringify(clientProducers))

    this.state.clientsProducers = clientProducers
  }
}

import { store as auth } from './auth'
import { store as clients } from './clients'
import { store as events } from './events'
import { store as i18n } from "./i18n"
import { store as loading } from "./loading"
import { store as logging } from './logging'
import { store as producers } from './producers'
import { store as reading } from './reading'
import { store as sectors } from './sectors'
import { store as snackbar } from "./snackbar"
import { store as vouchers } from "./vouchers"
import { store as user } from "./user"

const modules = {
  auth,
  clients,
  events,
  i18n,
  loading,
  logging,
  producers,
  reading,
  sectors,
  snackbar,
  vouchers,
  user,
};

export default modules;

const state = {
  locale: 'pt-BR',
}

const mutations = {
  set(_state, data) {
    _state.locale = data;
  },
}

const actions = {}

export const store = {
  namespaced: true,
  state: state,
  mutations,
  actions
}
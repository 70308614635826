export default {
  getClientsProducersEvents() {
    return this.state.clientsProducersEvents.length ? this.state.clientsProducersEvents : null
    || JSON.parse(localStorage.getItem('clients_producers_events'))
    || []
  },

  getClientsProducersEventsFiltered() {
    const selected = this.getSelected()

    return this.getClientsProducersEvents().filter(item => {
      return String(item.client) === String(selected.client.id) && String(item.producer) === String(selected.producer.id)
    })
  },

  setClientsProducersEvents(events) {
    let clientProducersEvents = this.getClientsProducersEvents().filter(item => String(item.client) !== String(this.getSelectedByKey('client').id) && String(item.producer) !== String(this.getSelectedByKey('producer').id))

    clientProducersEvents = [
      ...clientProducersEvents,
      ...events
    ]

    localStorage.setItem('clients_producers_events', JSON.stringify(clientProducersEvents))

    this.state.clientsProducersEvents = clientProducersEvents
  }
}

import Vue from 'vue';
import App from './App.vue';
import axios from '@/plugins/axios';
import moment from 'moment';
import store from "./store";
import router from './router/router';
import vuetify from './plugins/vuetify';
import globals from './globals';
import i18n from '@/plugins/i18n';
import snackbar from "./plugins/snackbar";
import './plugins/storage';
import './registerServiceWorker';

Vue.config.productionTip = false

Vue.prototype.$globals = globals
Vue.prototype.$http = axios
Vue.prototype.$moment = moment
Vue.prototype.$snackbar = snackbar;

new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')

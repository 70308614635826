<template>
  <v-row>
    <v-col
      cols="12"
      class="sub-heading"
    >
      <h3>
        <v-icon
          left
        >
          mdi-connection
        </v-icon>
        Conectividade
      </h3>
    </v-col>
    <v-col
      cols="12"
    >
      <v-text-field
        dense
        outlined
        hide-details
        label="API do torcedor de vantagens"
        prepend-inner-icon="mdi-api"
      />
    </v-col>
    <v-col
      cols="12"
    >
      <v-text-field
        dense
        outlined
        hide-details
        label="API do ingresso de vantagens"
        prepend-inner-icon="mdi-api"
      />
    </v-col>
    <v-col
      cols="12"
    >
      <v-text-field
        dense
        outlined
        hide-details
        label="API de leitura online"
        prepend-inner-icon="mdi-api"
      />
    </v-col>
    <v-col
      cols="12"
    >
      <v-text-field
        dense
        outlined
        hide-details
        label="Servidor de leitura local"
        prepend-inner-icon="mdi-network"
      />
    </v-col>
    <v-col
      v-if="showBackButton"
      cols="6"
    >
      <v-btn
        block
        outlined
        :to="backButtonTo"
        exact
      >
        <v-icon
          left
          small
        >
          mdi-arrow-left
        </v-icon>
        Voltar
      </v-btn>
    </v-col>
    <v-col>
      <v-btn
        block
        outlined
        @click="save()"
      >
        Salvar
        <v-icon
          right
          small
        >
          mdi-content-save
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ViewsPartialsConfigurationConnection',

  props: {
    showBackButton: {
      type: Boolean,
      default: true
    },
    backButtonTo: {
      type: [Object, String]
    },
    redirectToRouteNameAfterSave: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    //
  }),

  methods: {
    save() {
      if (this.redirectToRouteNameAfterSave) {
        this.$router.push({
          name: this.redirectToRouteNameAfterSave
        })
      }
    }
  }
}
</script>


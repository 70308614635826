<template>
  <layout-default :drawer-items="drawer.items">
    <div class="views offline report">
      <v-container>
        <v-row no-gutters>
          <v-col class="window">
            <layouts-partials-info-header :title="'report_subtitle'" :showSections="false" :showEvent="event !== null"/>
            <components-module-event-selector v-if="event === null" @submit="(e) => event = e"/>
            <components-module-event-report v-if="event !== null" :event="event" />
            <v-btn
              v-if="event !== null"
              block
              x-large
              color="secondary"
              @click="event = null"
              class="mt-6"
            >
              {{ $t("return") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </layout-default>
</template>

<script>
import ComponentsModuleEventReport from '@/components/module/EventReport'
import ComponentsModuleEventSelector from '@/components/module/EventSelector'
import LayoutsPartialsInfoHeader from '@/layouts/partials/InfoHeader'
import LayoutDefault from '@/layouts/Default'
import ViewsMixins from '@/views/mixins/mixins'
import store from '@/store/store'

export default {
  name: `OfflineIndex`,

  components: {
    ComponentsModuleEventReport,
    ComponentsModuleEventSelector,
    LayoutsPartialsInfoHeader,
    LayoutDefault,
  },

  mixins: [
    ViewsMixins
  ],
  
  data: () => ({
    event: null,
    store,
    drawer: {
      items: [
        {
          icon: 'fa-solid fa-shield-halved',
          title: 'producers',
          toRouteName: 'producer_index'
        },
        {
          icon: 'fa-solid fa-calendar-days',
          title: 'events',
          toRouteName: 'event_index'
        },
        {
          icon: 'mdi-barcode-scan',
          title: 'reading',
          toRouteName: 'index'
        },
        {
          icon: 'fa-solid fa-grid-2',
          title: 'sections',
          toRouteName: 'offline_section'
        },
        {
          icon: 'mdi-chart-line',
          title: 'reports',
          toRouteName: 'report'
        },
        {
          icon: 'mdi-database',
          title: 'cargo',
          items: [
            {
              icon: 'mdi-database-cog',
              title: 'manage',
              toRouteName: 'offline_manage'
            }
          ]
        }
      ]
    }
  }),
  
  methods: {
    
  },

  mounted() {
    const params = this.$route.params

    if (params && params.client && params.producer && params.event) {
      this.event = store.getClientsProducersEventsLocalFiltered({
        client: { id: params.client },
        producer: { id: params.producer },
        event: { id: params.event }
      })
    }
  },

  computed: {
    client() {
      return this.$store.state.clients.selected
    },
  }
}
</script>

<style lang="scss">

</style>

<template>
  <v-snackbar
    v-model="visible"
    top
    timeout="-1"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="blue"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'ComponentsSingletonFlashMessage',

  data: () => ({
    visible: false,
    message: ''
  }),

  mounted() {
    this.$root.$on('flash_message', (message) => {
      this.message = message
      this.visible = true
    })


  }
}
</script>


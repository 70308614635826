<template>
  <v-row class="mt-2" style="font-size: 1.15em">
    <v-col v-if="client" class="pa-0 d-flex flex-column justify-center align-center" cols="12">
      <span v-if="!title">
        {{$t('application_subtitle')}}
      </span>
      <span v-else>
        {{$t(title)}}
      </span>
      <small> {{ client.name }} </small>
    </v-col>
    <v-col class="px-0" cols="12">
      <v-divider/>
    </v-col>
    <v-col v-if="showEvent && event" class="pa-0 d-flex flex-column justify-center align-center" cols="12">
      <span> {{ event.title }} </span>
      <small> {{ event.type }} </small>
    </v-col>
    <v-col v-if="showEvent && event" class="px-0" cols="12">
      <v-divider/>
    </v-col>
    <v-col v-if="showSections && sections && sections.length > 0" class="pa-0 d-flex flex-column justify-center align-center mb-4 px-8 text-center" cols="12">
      <span style="font-size: 14px">
        Setores: {{ sectionText }}
      </span>
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: {
    showEvent: {
      type: Boolean,
      default: true,
      required: false,
    },
    showSections: {
      type: Boolean,
      default: true,
      required: false,
    },
    title: {
      type: String,
      required: false,
    }
  },
  computed: {
    event() {
      return this.$store.state.events.selected
    },
    client() {
      return this.$store.state.clients.selected
    },
    sections() {
      return this.$store.state.sectors.selected
    },
    sectionText() {
      return this.sections.map((s) => s.title).join(' / ')
    }
  }
}
</script>
import moment from "moment";
import VoucherService from "../../services/VoucherService";
import Vue from "vue";

const state = {
  data: {},
  history: {},
  selected: null,
}

const mutations = {
  set(_state, { event, vouchers }) {
    Vue.set(_state.data, event.id, vouchers);
    Vue.set(_state.history, event.id, []);
  },
  select(_state, data) {
    _state.selected = data;
  },
  clear(_state, { event = null }) {
    if (event !== null) {
      Vue.delete(_state.data, event.id);
      Vue.delete(_state.history, event.id);
    } else {
      _state.data = {};
    }
  },
  setAsRead(_state, { voucher }) {
    voucher.checkin_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
  },
  pushToHistory(_state, { event, data }) {
    _state.history[event.id].push(data);
  }
}

const actions = {
  async fetch(context) {
    try {
      const client = context.rootState.clients.selected
      const producer = context.rootState.producers.selected
      const event = context.rootState.events.selected
      const vouchers = await VoucherService.index(client, producer, event)
      
      let current = context.state.data[event.id];

      if (!current) current = [];

      const existent = new Set(current.map((v) => String(v.data)))
      
      let imported = 0;

      vouchers.map(
        voucher => {
          if (!existent.has(String(voucher.data))) {
            imported += 1;
            current.push(voucher)
            existent.add(String(voucher.data))
          }
        }
      )
      
      context.commit("set", { event, vouchers: current })

      return imported
    } catch (error) {
      context.commit(
        'snackbar/show', 
        {
          message: 'error_reading_clients',
          timeout: 5000,
          color: 'error'
        },
        { root: true }
      )
    }
  },
  async pushToHistory(context, { code, event, voucher, status, type }) {
    let result = {
      code,
      status,
      voucher,
      success: null,
      already_read: null,
      forbidden_in_section: null,
      not_found_invalid: null,
      read_mode: null
    }

    result[type] = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    result.read_mode = context.rootState.reading.selected

    context.commit("pushToHistory", { event, data: result })
  }
}

export const store = {
  namespaced: true,
  state,
  mutations,
  actions
}
import moment from 'moment'
import saveAppLogs from '../../api/requests/saveAppLogs';

const state = {
    stored: [],
    staged: [],
    timeout: null,
}

const mutations = {
    add(_state, { message, level = "info", channel = "stack" } ) {
        const timestamp = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        _state.staged.push({ timestamp, message, level, channel})
    },
};

const actions = {
    add({ commit, dispatch }, log) {
        commit("add", log)

        if (state.timeout !== null) clearTimeout(state.timeout);

        state.timeout = setTimeout(() => dispatch("persist"), 5000);
    },
    async persist({ state }) {
        try {
            await saveAppLogs(state.staged)
            state.stored = [...state.stored, ...state.staged]
            state.staged = []
        } catch (error) {
            console.log(error);
        }
    }
}

export const store = {
    namespaced: true,
    state,
    mutations,
    actions,
};
  
import moment from 'moment'

export default {
  getClientsProducersEventsVouchersHistory() {
    return this.state.clientsProducersEventsVouchersHistory.length ? this.state.clientsProducersEventsVouchersHistory : null
    || JSON.parse(localStorage.getItem('clients_producers_events_vouchers_history'))
    || []
  },

  getClientsProducersEventsVouchersHistoryFiltered(filter) {
    let selected = this.getSelected()

    if (filter) {
      selected = filter
    }

    const vouchers =  this.getClientsProducersEventsVouchersHistory().find(item => String(item.client) === String(selected.client.id) && String(item.producer) === String(selected.producer.id) && String(item.event) === String(selected.event.id))

    if (vouchers) {
      return vouchers.history
    }

    return []
  },

  getClientsProducersEventsVouchersHistoryByStatus(status) {
    const history = this.getClientsProducersEventsVouchersHistoryFiltered()

    let result = []

    const readMode = this.getModuleActive()

    if (history) {
      result = history.filter(item => item[status] && item.read_mode === readMode)
    }

    return result
  },

  setClientsProducersEventsVouchersHistory(code, status, voucher = null) {
    const eventsVouchersHistory = this.getClientsProducersEventsVouchersHistory()

    const selected = this.getSelected()

    let target = eventsVouchersHistory.find(item => String(item.client) === String(selected.client.id) && String(item.producer) === String(selected.producer.id) && String(item.event) === String(selected.event.id))

    if (! target) {
      target = {
        client: selected.client.id,
        producer: selected.producer.id,
        event: selected.event.id,
        data: {
          client: selected.client,
          producer: selected.producer,
          event: selected.event
        },
        history: []
      }

      eventsVouchersHistory.push(target)
    }

    let result = {
      code,
      voucher,
      success: null,
      already_read: null,
      forbidden_in_section: null,
      not_found_invalid: null,
      read_mode: null
    }

    result[status] = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    result.read_mode = this.getModuleActive()

    target.history.push(result)

    localStorage.setItem('clients_producers_events_vouchers_history', JSON.stringify(eventsVouchersHistory))

    this.state.clientsProducersEventsVouchersHistory = eventsVouchersHistory
  }
}

<template>
  <layout-clean>
    <div
      class="views auth index d-flex flex-column justify-center align-center"
    >
      <v-container>
        <v-row>
          <v-col class="d-flex flex-column justify-center align-center" cols="12">
            <v-img max-width="150" src="@/assets/grupo_dataclick.svg"></v-img>
          </v-col>
          <v-col class="d-flex flex-column justify-center align-center" cols="12">
            <p>{{$t('application_subtitle')}}</p>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="justify-center"
        >
          <v-col
            class="window"
          >
            <v-card
              flat
              class="common"
              color="transparent"
            >
              <v-card-text>
                <components-common-flash-message />
                <v-alert
                  v-show="error"
                  dark
                  dense
                  prominent
                  icon="mdi-account-alert"
                  color="red"
                >
                  {{ $t(error) }}
                </v-alert>
                <v-form
                  ref="views_auth_index_form"
                  v-model="form.valid"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="form.fields.client.value"
                        outlined
                        hide-details="auto"
                        :label="$t('client_application')"
                        item-text="title"
                        item-value="id"
                        :items="clients"
                        :loading="httpIsLoading"
                        :no-data-text="$t('no_data')"
                        :rules="[form.fields.client.rules.required]"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="form.fields.user.value"
                        outlined
                        hide-details="auto"
                        :label="$t('user')"
                        append-icon="fas fa-user"
                        :rules="[
                          form.fields.user.rules.required,
                          form.fields.user.rules.min_length
                        ]"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="form.fields.password.value"
                        outlined
                        :label="$t('password')"
                        append-icon="fas fa-lock"
                        type="password"
                        :rules="[form.fields.password.rules.required]"
                      />
                    </v-col>
                    <v-col>
                      <v-btn
                        block
                        color="primary"
                        x-large
                        :loading="httpIsLoading"
                        @click="authenticate()"
                      >
                        {{ $t('login') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </layout-clean>
</template>

<script>
import ComponentsCommonFlashMessage from '@/components/common/FlashMessage'
import LayoutClean from '@/layouts/Clean'
import ViewsMixins from '@/views/mixins/mixins'

export default {
  name: 'AuthIndex',

  mixins: [
    ViewsMixins
  ],

  components: {
    ComponentsCommonFlashMessage,
    LayoutClean
  },
  
  data: () => ({
    error: '',
    form: {
      valid: false,
      fields: {
        client: {
          rules: {
            required: value => !! value || 'Selecione uma plataforma'
          }
        },
        user: {
          value: '',
          rules: {
            required: value => !! value || 'Informe um usuário',
            min_length: value => value.length >= 3 || 'Usuário precisa ter no mínimo 3 caracrteres'
          }
        },
        password: {
          value: '',
          rules: {
            required: value => !! value || 'Informe uma senha'
          }
        }
      }
    }
  }),

  mounted() {
    this.$store.dispatch('clients/fetch')
  },
  
  methods: {
    async authenticate() {
      this.error = null

      if (! this.$refs.views_auth_index_form.validate()) {
        return
      }

      try {
        await this.$store.dispatch("auth/login", {
          client_id: this.form.fields.client.value,
          user: this.form.fields.user.value,
          password: this.form.fields.password.value
        })
        
        this.$router.push({
          name: 'producer_index'
        })
      } catch (error) {
        this.error = 'invalid_credentials'
      }
    }
  },

  computed: {
    clients() {
      return this.$store.state.clients.data;
    }
  }
}
</script>

<style lang="scss">
.views {
  &.auth {
    &.index {
      .window {
        max-width: 320px;
      }
    }
  }
}
</style>

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'
import '@fortawesome/fontawesome-pro/css/all.css'

const locales = {
  en: require('vuetify/es5/locale/en'),
  pt: require('vuetify/es5/locale/pt')
}

const current = navigator.language.split('-')[0]

Vue.use(Vuetify)

export default new Vuetify({
  locales,
  current,
  icons: {
    iconfont: 'fa'
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: colors.orange.darken2,
      }
    }
  }
})

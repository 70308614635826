<template>
  <div>
    <router-view />
    <component-common-notification />
    <components-singleton-flash-message />
  </div>
</template>

<script>
import ComponentCommonNotification from '@/components/common/Notification'
import ComponentsSingletonFlashMessage from '@/components/singleton/FlashMessage'

export default {
  name: `App`,

  components: {
    ComponentCommonNotification,
    ComponentsSingletonFlashMessage
  }
}
</script>

<style lang="scss">
$app-background-color: #333333;

.app {
  background-color: $app-background-color !important;

  .common {
    &.bg-alpha-dark-01 {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .main {
    background-color: $app-background-color !important;
  }

  .footer {
    background-color: transparent !important;
  }
}

.views {
  // min-height: 96% !important;
  min-width: 100% !important;
}

.v-main__wrap {
  display: flex;
  flex-direction: column;
}

/** GLOBAL OVERRIDES */
div {
  &.col {
    h1 {
      &.heading {
        border-bottom: rgba(255, 255, 255, 0.05) solid 3px;
        margin-bottom: 20px;
      }
    }

    &.sub-heading {
      padding-bottom: 0 !important;
    }
  }
}
</style>

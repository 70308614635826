import { store as online } from "./online";
import { store as offline } from "./offline";
import { store as network } from "./network";

const modules = {
  online,
  offline,
  network
}

const state = {
  selected: null,
  errors: {
    422: { 
      message: 'reading_error_voucher_already_read',
      type: 'already_read',
    },
    406: { 
      message: 'reading_error_voucher_forbidden_in_section',
      type: 'forbidden_in_section',
    },
    404: { 
      message: 'reading_error_voucher_not_found_or_invalid',
      type: 'not_found_invalid',
    },
    403: { 
      message: 'reading_error_voucher_forbidden',
      type: 'forbidden',
    },
  }
}

const mutations = {
  setActiveModule(_state, _module) {
    _state.selected = _module;
  },
}

const getters = {
  modules() {
    return [
      online.state,
      network.state,
      offline.state,
    ]
  },
  selected(_state) {
    return {
      offline,
      online,
      network
    }[_state.selected]
  }
}

const actions = {
  async read(context, { client, producer, event, data }) {
    const type = context.state.selected
    
    if (type === null) throw "Can't read without a type setted"
    
    const response = await context.dispatch(`${type}/read`, { client, producer, event, data })

    if (response.type === 'error') {
      const error = context.state.errors[response.error.status];

      context.dispatch(
        "vouchers/pushToHistory", 
        { 
          event, 
          voucher: response.voucher, 
          status: response.error.status, 
          type: error.type,
          code: data,
        }, 
        { root: true }
      )
      
      return {
        message: error.message,
        history: error.type,
        type: 'error',
        voucher: response.voucher,
        data: ''
      }
    }

    context.dispatch(
      "vouchers/pushToHistory", 
      { 
        event,
        voucher: response.voucher,
        status: 200,
        type: "success",
        code: data,
      }, 
      { root: true }
    )

    return response;
  }
}

export const store = {
  namespaced: true,
  state,
  mutations,
  actions,
  modules,
  getters,
}
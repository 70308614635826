import router from '@/router/router'
import RouterGuardsApp from '@/router/guards/app'
import store from '@/store/index'

export default {
  authorization() {
    return RouterGuardsApp.moduleAuthorization()
  },

  indexAuthorization() {
    const sections = store.state.sectors.selected
    
    if (! sections || (sections && ! sections.length)) {
      sessionStorage.setItem('flash_message', JSON.stringify({
        type: 'info',
        message: 'sections_info_undefined'
      }))
      
      router.push({
        name: 'online_section'
      })

      return false
    }

    return true
  }
}

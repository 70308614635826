import globalMixins from '@/mixins/mixins'

export default {
  mounted() {
    //window.addEventListener('online', updateConnectionStatus);
    //window.addEventListener('offline', updateConnectionStatus);
  },

  methods: {

  },

  computed: {
    ...globalMixins.computed,
  }
}

const state = {
  route: 'offline',
  icon: 'fas fa-wifi-slash',
  title: 'offline',
}

const actions = {
  read(context, { event, data }) {
    try {
      const vouchers = context.rootState.vouchers.data[event.id]

      if (!vouchers || !vouchers.length) {
        return
      }

      const sections = context.rootState.sectors.selected.map(item => item.id)

      let response = {
        type: 'error',
        error: {
          status: 200,
          checkin_at: null,
        },
        voucher: null,
      }

      const voucher = vouchers.find(
        item => {
          const dataCondition = String(item.data).substring(0, 12) === String(data).substring(0, 12)
          const documentCondition = item.holder.document !== null && item.holder.document !== "" && String(item.holder.document) === String(data) && !item.checkin_at

          return dataCondition || documentCondition
        }
      )

      if(!voucher) {
        // Voucher does not exists
        response.error.status = 404
      } else if (voucher.checkin_at) {
        // Voucher already checkin
        response.error.status = 422
        response.error.checkin_at = voucher.checkin_at
        response.voucher = voucher
      } else if (voucher.sections === null) {
        // Voucher not allowed in any section e.g. access denied
        response.error.status = 403
        response.voucher = voucher
      } else if (!voucher.checkin_at && !voucher.sections.find(item => sections.includes(item))) {
        // Voucher not in required sections
        response.error.status = 406
        response.voucher = voucher
      }

      if (response.error.status !== 200) {
        return new Promise((resolve) => {
          resolve(response);
        })
      }

      return new Promise((resolve) => {
        // Voucher is ok
        context.commit("vouchers/setAsRead", { voucher }, { root: true })

        resolve({
          message: 'reading_success',
          type: 'success',
          voucher: voucher,
          data: ''
        })
      })
    } catch (error) {
      return new Promise((resolve, reject) => {
        reject({
          type: 'error',
          error: {
            status: 500,
            checkin_at: null,
          }
        })
      })
    }
  }
}

export const store = {
  namespaced: true,
  state,
  actions
}
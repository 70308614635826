<template>
  <layout-default>
    <div class="views network section">
      <v-container>
        <info-header/>
        <v-row no-gutters>
          <v-col class="window">
            <v-card color="transparent" flat>
              <v-card-text class="mb-4">
                <v-row>
                  <v-col>
                    <components-common-flash-message />
                    <components-module-section
                      :redirectOnSave="{
                        name: 'network_index'
                      }"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </layout-default>
</template>

<script>
import ComponentsCommonFlashMessage from '@/components/common/FlashMessage'
import ComponentsModuleSection from '@/components/module/Section'
import LayoutDefault from '@/layouts/Default'
import InfoHeader from '@/layouts/partials/InfoHeader'

export default {
  name: `OnlineSection`,

  components: {
    ComponentsCommonFlashMessage,
    ComponentsModuleSection,
    LayoutDefault,
    InfoHeader
  },
  
  data: () => ({
    //
  }),

  mounted() {
    //
  },
  
  methods: {
    //
  }
}
</script>

<style lang="scss">
.views {
  &.network {
    &.section {
      //
    }
  }
}

@media screen and (max-width: 600px) {
  .views {
    &.network {
      &.section {
        //
      }
    }
  }
}
</style>

<template>
  <div class="components module event-report">
    <!-- First table -->
    <v-simple-table class="report-table mt-6">
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="text-left font-weight-bold">
              {{ $t('event_date') }}
            </td>
            <td class="text-right font-weight-bold">
              {{ event.calendar.date }}
            </td>
          </tr>
          <tr class="">
            <td>
              {{ $t('event_start_hour') }}
            </td>
            <td class="text-right">
              {{ event.calendar.time.start }}
            </td>
          </tr>
          <tr class="">
            <td>
              {{ $t('event_opening_hour') }}
            </td>
            <td class="text-right">
              {{ event.calendar.time.gate_opening }}
            </td>
          </tr>
          <tr class="">
            <td>
              {{ $t('event_ending_hour') }}
            </td>
            <td class="text-right">
              {{ event.calendar.time.gate_closing }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- First table -->
    <v-simple-table class="report-table mt-6">
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="text-left font-weight-bold">
              {{ $t('readings_by_status') }}
            </td>
            <td class="text-right font-weight-bold">
              {{ $tc('totals', 1) }}
            </td>
          </tr>
          <tr class="">
            <td>
              {{ $t('report_reading_success') }}
            </td>
            <td class="text-right">
              {{ totalSuccess }}
            </td>
          </tr>
          <tr class="">
            <td>
              {{ $t('report_reading_error_voucher_already_read') }}
            </td>
            <td class="text-right">
              {{ totalAlreadyRead }}
            </td>
          </tr>
          <tr class="">
            <td>
              {{ $t('report_reading_error_voucher_forbidden_in_section') }}
            </td>
            <td class="text-right">
              {{ totalForbiddenInSection }}
            </td>
          </tr>
          <tr class="">
            <td>
              {{ $t('report_reading_error_voucher_not_found_or_invalid') }}
            </td>
            <td class="text-right">
              {{ totalNotFoundInvalid }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- Second table -->
    <v-simple-table class="report-table mt-6">
      <template v-slot:default>
        <tbody>
          <tr>
            <td colspan="3" class="text-left font-weight-bold">
              {{ $t('readings_by_types') }}
            </td>
            <td colspan="1" class="text-right font-weight-bold">
              {{ $tc('totals', 1) }}
            </td>
          </tr>
          <tr v-for="_type in types" :key="_type">
            <td colspan="3">
              {{ _type }}
            </td>
            <td colspan="1" class="text-right">
              {{ getHistoryCountFromType(_type) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- Third table -->
    <v-simple-table class="report-table mt-6">
      <template v-slot:default>
        <tbody>
          <tr>
            <td colspan="3" class="text-left font-weight-bold">
              {{ $t('readings_by_sections') }}
            </td>
            <td colspan="1" class="text-right font-weight-bold">
              {{ $tc('totals', 1) }}
            </td>
          </tr>
          <tr v-for="section in sections" :key="section.id">
            <td colspan="3" style="text-transform: capitalize">
              {{ section.title.toLowerCase() }}
            </td>
            <td colspan="1" class="text-right">
              {{ getHistoryCountFromSection(section) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- Third table -->
    <v-simple-table class="report-table mt-6">
      <template v-slot:default>
        <tbody>
          <tr>
            <td colspan="3" class="text-left font-weight-bold">
              {{ $t('stat') }}
            </td>
            <td colspan="1" class="text-right font-weight-bold">
              {{ $tc('value') }}
            </td>
          </tr>
          <tr>
            <td colspan="3" class="text-left">
              {{ $t('average_reading_time') }}
            </td>
            <td colspan="1" class="text-right">
              {{ (averageReadingTime / 1000).toFixed(2) }} s
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>

export default {
  name: 'ComponentsModuleEventReport',

  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    showSections: {
      type: Boolean,
      default: false
    },
    showFinish: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    loading: {
      finish: false
    },
    error: null,
    dialog_finish: false
  }),

  methods: {
    getHistoryCountFromType(type) {
      return this.success.filter(item => item.voucher.type === type).length
    },
    getHistoryCountFromSection(section) {
      return this.success.filter(item => item.voucher.sections[0] === section.id).length
    }
  },

  computed: {
    history() {
      return this.$store.state.vouchers.history[this.event.id];
    },

    types() {
      return [... new Set(this.success.map(item => item.voucher.type))].sort()
    },

    sections() {
      const event = this.$store.state.events.selected

      const vouchers = this.$store.state.vouchers.data[this.event.id]

      const sectionsWithAtleastOneVoucher = new Set(vouchers.map(v => v.sections).flat())

      const sections = event.sections.filter(s => sectionsWithAtleastOneVoucher.has(s.id))

      if (sections) {
        return sections.toSorted((a, b) => {
          if (a.title > b.title) return 1
          else return -1
        })
      }

      return []
    },

    success() {
      return this.history.filter(item => item.success)
    },

    totalSuccess() {
      return this.history.filter(item => item.success).length
    },

    totalAlreadyRead() {
      return this.history.filter(item => item.already_read).length
    },

    totalForbiddenInSection() {
      return this.history.filter(item => item.forbidden_in_section).length
    },

    totalNotFoundInvalid() {
      return this.history.filter(item => item.not_found_invalid).length
    },

    averageReadingTime() {
      const withBenchmark = this.success.filter(item => Object.hasOwn(item.voucher, 'benchmark'))

      if (withBenchmark.length == 0) {
        return 0
      }

      return withBenchmark.reduce((a, b) => a + b.voucher.benchmark, 0) / withBenchmark.length
    }
  }
}
</script>

<style>
  .report-table {
    background-color: transparent !important;
  }

  .report-table table,
  .report-table td,
  .report-table tr {
    background-color: transparent !important;
    border: 1px dashed #ccc;
    border-collapse: collapse;
  }

  .report-table td {
    height: 30px !important;
  }

  .report-table td:last-child {
    width: 110px;
  }
</style>

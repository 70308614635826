<template>
  <layout-default :drawer-items="drawer.items">
    <div class="views online index">
      <v-container>
        <info-header/>
        <component-common-reader />
        <v-row>
          <v-divider class="mt-3"/>
        </v-row>
        <components-common-voucher-history />
        <components-module-event-finish :showFinish="true" :event="event" />
      </v-container>
    </div>
  </layout-default>
</template>

<script>

import ComponentCommonReader from '@/components/common/Reader.vue'
import ComponentsCommonVoucherHistory from '@/components/common/VoucherHistory'
import ComponentsModuleEventFinish from '@/components/module/EventFinish'
import LayoutDefault from '@/layouts/Default'
import InfoHeader from '@/layouts/partials/InfoHeader'
import RouterGuardsOnline from '@/router/guards/online'

export default {
  name: `OnlineIndex`,

  components: {
    ComponentCommonReader,
    ComponentsCommonVoucherHistory,
    ComponentsModuleEventFinish,
    LayoutDefault,
    InfoHeader
  },

  created() {
    RouterGuardsOnline.indexAuthorization()
  },
  
  data: () => ({
    clients: [],
    drawer: {
      items: [
        {
          icon: 'fa-solid fa-shield-halved',
          title: 'producers',
          toRouteName: 'producer_index'
        },
        {
          icon: 'fa-solid fa-calendar-days',
          title: 'events',
          toRouteName: 'event_index'
        },
        {
          icon: 'mdi-barcode-scan',
          title: 'reading',
          toRouteName: 'index'
        },
        {
          icon: 'fa-solid fa-grid-2',
          title: 'sections',
          toRouteName: 'online_section'
        },
        {
          icon: 'mdi-chart-line',
          title: 'reports',
          toRouteName: 'report'
        },
        {
          icon: 'mdi-math-log',
          title: 'logs',
          toRouteName: 'logs'
        },
      ]
    }
  }),
  
  methods: {
    //
  },

  computed: {
    event() {
      return this.$store.state.events.selected
    }
  }
}
</script>

<style lang="scss">
.views {
  &.online {
    &.index {
      //
    }
  }
}

@media screen and (max-width: 600px) {
  .views {
    &.online {
      &.index {
        //
      }
    }
  }
}
</style>

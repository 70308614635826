import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import localForage from "localforage";

import modules from "./modules";

Vue.use(Vuex);

const persistence = new VuexPersistence({
  storage: localForage,
  asyncStorage: true,
  modules: [
    'auth',
    'clients',
    'events',
    'logging',
    'localization',
    'producers',
    'reading',
    'sectors',
    'snackbar',
    'vouchers',
    'user',
  ]
});

export default new Vuex.Store({
  state: undefined,
  mutations: {},
  actions: {},
  modules: modules,
  plugins: [ persistence.plugin ]
});

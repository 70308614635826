<template>
  <div class="reading-alert my-4" style="width: 100%">
    <v-card :color="config[type].color">
      <v-card-text class="d-flex flex-column align-center justify-center">
        <v-icon size="72"> {{config[type].icon}} </v-icon>
        <h2 style="text-transform: uppercase; text-align: center" class="my-3"> {{$t(text)}} </h2>
        <h3 style="text-align: center" v-if="voucher && voucher.holder && voucher.holder.name"> {{ voucher.holder.name.toUpperCase() }} </h3>
        <small style="font-size: 16px" v-if="voucher && voucher.holder">
          <span v-if="voucher.holder.type"> {{ voucher.holder.type.toUpperCase() }} </span>
          <span v-if="voucher.holder.type && voucher.holder.document"> | </span>
          <span v-if="voucher.holder.document"> {{ voucher.holder.document}} </span>
        </small>
        <h3 v-if="type === 'error' && voucher && voucher.checkin_at"> HORÁRIO LEITURA: {{ $moment(voucher.checkin_at).format('DD/MM/YYYY HH:mm:ss') }} </h3>
        <h3 v-if="section">
          <span> {{ section }} </span>
        </h3>
        <h3 v-if="voucher">
          <span v-if="voucher.type"> {{ voucher.type.toUpperCase() }} </span>
          <span v-if="voucher.value"> | {{ $n(voucher.value, 'currency') }} </span>
        </h3>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        success: {
          color: 'green',
          icon: 'far fa-circle-check'
        },
        error: {
          color: 'red',
          icon: 'far fa-circle-xmark'
        },
      }
    }
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'success'
    },
    text: {
      type: String,
      required: true,
    },
    voucher: {
      type: Object,
      required: false,
    },
    response: {
      type: Object,
      required: false,
    }
  },
  computed: {
    section() {
      if (!this.voucher || !this.voucher.sections) return null

      const event = this.$store.state.events.selected
      const sectors = this.$store.state.sectors.selected

      if (!event) {
        return null
      }
      
      const found = []

      for (const section of this.voucher.sections) {
        const search = sectors.find((s) => s.id == section)

        if (search) {
          found.push(search)
        }
      }

      if (found.length > 0) {
        return found.map(e => e.title).sort().join(', ').toUpperCase()
      }

      return null
    }
  }
}
</script>

export default {
  api: {
    baseUrl: 'https://api.acesso.clickersports.com.br'
    // baseUrl: 'http://localhost'
  },
  application: {
    name: 'DTK Acesso',
    version: '1.0.6',
    requestHeaderDataKey: 'SeverinoSettings'
  }
}

const state = {
  data: [],
  selected: [],
}

const mutations = {
  set(_state, data) {
    _state.data = data;
  },
  select(_state, data) {
    _state.selected = data;
  },
  clear(_state) {
    _state.data = [];
    _state.selected = [];
  },
}

const actions = {}

export const store = {
  namespaced: true,
  state,
  mutations,
  actions
}
<template>
  <v-app
    class="app"
  >
    <v-progress-linear
      v-show="httpIsLoading"
      indeterminate
      striped
      color="blue"
      height="5"
      style="position: absolute"
    />
    <v-app-bar
      app
      dense
      flat
      class="bar"
    >
      <v-spacer />
      <components-settings-language />
      <v-btn
        v-if="isLogged"
        small
        class="ml-2"
        color="red"
        :to="{
          name: 'auth_logout'
        }"
      >
        {{ $t('logout') }}
        <v-icon
          small
        >
          mdi-logout
        </v-icon>
      </v-btn>
    </v-app-bar>
    <v-main
      class="app main"
    >
      <slot />
    </v-main>
    <layout-partial-footer
      v-if="showFooter"
    />
  </v-app>
</template>

<script>
import ComponentsSettingsLanguage from '@/components/settings/Language'
import LayoutsMixins from '@/layouts/mixins/mixins'
import LayoutPartialFooter from '@/layouts/partials/Footer'

export default {
  name: `LayoutsClean`,
  
  mixins: [
    LayoutsMixins
  ],

  components: {
    ComponentsSettingsLanguage,
    LayoutPartialFooter
  },

  props: {
    showFooter: {
      type: Boolean,
      default: true
    },
    showQuickConfiguration: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    user: null
  }),

  computed: {
    isLogged() {
      return this.$store.getters['auth/logged'] 
    },

    languageFlag() {
      if (this._languagesFiltered) {
        return this._languagesFiltered.icon
      }

      return ''
    }
  }
}
</script>

<style lang="scss">
.app {
  .bar {
    background-color: transparent !important;
  }
}
</style>

import moment from 'moment'

export default {
  getClientsProducersEventsLocal() {
    return this.state.clientsProducersEventsLocal.length ? this.state.clientsProducersEventsLocal : null
    || JSON.parse(localStorage.getItem('clients_producers_events_local'))
    || []
  },

  getClientsProducersEventsLocalFiltered(filter) {
    let selected = this.getSelected()

    if (filter) {
      selected = filter
    }

    return this.getClientsProducersEventsLocal().find(item => String(item.client) === String(selected.client.id) && String(item.producer) === String(selected.producer.id) && String(item.id) === String(selected.event.id))
  },

  getClientsProducersEventsLocalByFinishedValue(value) {
    return this.getClientsProducersEventsLocal().filter(item => item.finished === value && ! item.deleted_at) || []
  },

  setClientsProducersEventsLocal() {
    const eventsLocal = this.getClientsProducersEventsLocal()

    const selected = this.getSelected()

    let target = eventsLocal.find(item => String(item.client) === String(selected.client.id) && String(item.producer) === String(selected.producer.id) && String(item.id) === String(selected.event.id))

    if (! target) {
      delete selected.event.sections

      target = {
        ...selected.event,
        data: {
          client: selected.client,
          producer: selected.producer
        },
        finished: false,
        deleted_at: null
      }

      eventsLocal.push(target)
    }

    localStorage.setItem('clients_producers_events_local', JSON.stringify(eventsLocal))

    this.state.clientsProducersEventsLocal = eventsLocal
  },

  setClientsProducersEventsLocalFinished(client, producer, event) {
    const eventsLocal = this.getClientsProducersEventsLocal()

    const target = eventsLocal.find(item => String(item.client) === String(client) && String(item.producer) === String(producer) && String(item.id) === String(event))

    target.finished = true

    localStorage.setItem('clients_producers_events_local', JSON.stringify(eventsLocal))

    this.state.clientsProducersEventsLocal = eventsLocal
  },

  setClientsProducersEventsLocalDeletedAt(client, producer, event) {
    const eventsLocal = this.getClientsProducersEventsLocal()

    const target = eventsLocal.find(item => String(item.client) === String(client) && String(item.producer) === String(producer) && String(item.id) === String(event))

    target.deleted_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

    localStorage.setItem('clients_producers_events_local', JSON.stringify(eventsLocal))

    this.state.clientsProducersEventsLocal = eventsLocal
  }
}

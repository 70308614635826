import AuthService from "../../services/AuthService";

const state = {
  token: null,
  user: null,
}

const mutations = {
  setToken(_state, token) {
    _state.token = token;
  },
  clearToken(_state) {
    _state.token = null;
  },
}

const getters = {
  logged(_state) {
    return _state.token !== null;
  }
}

const actions = {
  async login(context, { client_id, user, password }) {
    const token = await AuthService.login(client_id, user, password)
        
    context.dispatch("logging/add", { message: `Usuário ${user} fez login`}, { root: true })
    context.commit('setToken', token)

    const client = context.rootState.clients.data.find(item => item.id === client_id)
    context.commit('clients/select', client, { root: true })

    context.commit('producers/clear', null, { root: true });
    context.commit('events/clear', null, { root: true });
    context.commit('sectors/clear', null, { root: true });
  }
}

export const store = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
import Vue from 'vue'
import Router from 'vue-router'
import _store from '../store/index';

// Layouts
import LayoutsProtectedRouterViewEcho from '@/layouts/protected/RouterViewEcho'

// Router Guards
import RouterGuardsApp from '@/router/guards/app'
import RouterGuardsNetwork from '@/router/guards/network'
import RouterGuardsOffline from '@/router/guards/offline'
import RouterGuardsOnline from '@/router/guards/online'

// Views
import ViewsAuthIndex from '@/views/auth/Index'
import ViewsEventIndex from '@/views/event/Index'
import ViewsIndex from '@/views/Index'
import ViewsLogIndex from '@/views/logs/Index'
import ViewsNetworkIndex from '@/views/network/Index'
import ViewsNetworkConfiguration from '@/views/network/Configuration'
import ViewsNetworkSection from '@/views/network/Section'
import ViewsOfflineConfiguration from '@/views/offline/Configuration'
import ViewsOfflineExport from '@/views/offline/Export'
import ViewsOfflineImport from '@/views/offline/Import'
import ViewsOfflineIndex from '@/views/offline/Index'
import ViewsOfflineManage from '@/views/offline/Manage'
import ReportIndex from '@/views/report/Index'
import ViewsOfflineSection from '@/views/offline/Section'
import ViewsOnlineIndex from '@/views/online/Index'
import ViewsOnlineSection from '@/views/online/Section'
import ViewsProducerIndex from '@/views/producer/Index'


Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: `/`,
      name: `index`,
      component: ViewsIndex
    },
    {
      path: `/logs`,
      name: `logs`,
      component: ViewsLogIndex
    },
    {
      path: `/auth`,
      name: `auth`,
      component: LayoutsProtectedRouterViewEcho,
      redirect: {
        name: 'auth_index'
      },
      children: [
        {
          path: '',
          name: 'auth_index',
          component: ViewsAuthIndex
        },
        {
          path: 'logout',
          name: 'auth_logout',
          beforeEnter: (to, from, next) => {
            _store.commit("auth/clearToken");
            _store.commit("clients/clear");
            _store.commit("events/clear");
            _store.commit("producers/clear");
            _store.commit("sectors/clear");

            window.localStorage.clear()
            
            if(! RouterGuardsApp.authorization()) {
              sessionStorage.setItem('flash_message', JSON.stringify({
                type: 'info',
                message: 'disconnected'
              }))

              return
            }
    
            next()
          }
        }
      ]
    },
    {
      path: '/producers',
      name: 'producers',
      component: LayoutsProtectedRouterViewEcho,
      beforeEnter: (to, from, next) => {
        if (! RouterGuardsApp.moduleAuthorizationClient()) {
          return
        }

        next()
      },
      redirect: {
        name: 'producer_index'
      },
      children: [
        {
          path: '',
          name: 'producer_index',
          component: ViewsProducerIndex
        }
      ]
    },
    {
      path: '/events',
      name: 'events',
      component: LayoutsProtectedRouterViewEcho,
      beforeEnter: (to, from, next) => {
        if (! RouterGuardsApp.moduleAuthorizationClient() || ! RouterGuardsApp.moduleAuthorizationProducer()) {
          return
        }

        next()
      },
      redirect: {
        name: 'event_index'
      },
      children: [
        {
          path: '',
          name: 'event_index',
          component: ViewsEventIndex
        }
      ]
    },
    {
      path: `/offline`,
      name: `offline`,
      component: LayoutsProtectedRouterViewEcho,
      beforeEnter: (to, from, next) => {
        if(! RouterGuardsApp.moduleAuthorization() || ! RouterGuardsOffline.authorization()) {
          return
        }

        _store.commit('reading/setActiveModule', 'offline')

        next()
      },
      redirect: {
        name: 'offline_index'
      },
      children: [
        {
          path: '',
          name: 'offline_index',
          component: ViewsOfflineIndex
        },
        {
          path: 'report',
          name: 'report',
          component: ReportIndex
        },
        {
          path: 'report/:client/:producer/:event',
          name: 'report_specific',
          component: ReportIndex
        },
        {
          path: 'section',
          name: 'offline_section',
          component: ViewsOfflineSection
        },
        {
          path: 'manage',
          name: 'offline_manage',
          component: ViewsOfflineManage
        },
        {
          path: 'import',
          name: 'offline_import',
          component: ViewsOfflineImport
        },
        {
          path: 'export',
          name: 'offline_export',
          component: ViewsOfflineExport
        },
        {
          path: 'configuration',
          name: 'offline_configuration',
          component: ViewsOfflineConfiguration
        }
      ]
    },
    {
      path: '/network',
      name: 'network',
      component: LayoutsProtectedRouterViewEcho,
      beforeEnter: (to, from, next) => {
        if (! RouterGuardsApp.moduleAuthorization() || ! RouterGuardsNetwork.authorization()) {
          return
        }

        _store.commit('reading/setActiveModule', 'network')

        next()
      },
      redirect: {
        name: 'network_index'
      },
      children: [
        {
          path: '',
          name: 'network_index',
          component: ViewsNetworkIndex
        },
        {
          path: 'section',
          name: 'network_section',
          component: ViewsNetworkSection
        },
        {
          path: 'configuration',
          name: 'network_configuration',
          component: ViewsNetworkConfiguration
        }
      ]
    },
    {
      path: '/online',
      name: 'online',
      component: LayoutsProtectedRouterViewEcho,
      beforeEnter: (to, from, next) => {
        if (! RouterGuardsApp.moduleAuthorization() || ! RouterGuardsOnline.authorization()) {
          return
        }

        _store.commit('reading/setActiveModule', 'online')

        next()
      },
      redirect: {
        name: 'online_index'
      },
      children: [
        {
          path: '',
          name: 'online_index',
          component: ViewsOnlineIndex
        },
        {
          path: 'section',
          name: 'online_section',
          component: ViewsOnlineSection
        }
      ]
    },
  ],
  mode: `history`
})

router.beforeEach(async (to, from, next) => {
  await _store.restored;
  next();
})

// Aet global app router guard
router.beforeEach((to, from, next) => {
  if(to.name !== 'auth_index' && ! RouterGuardsApp.authorization()) {
    return
  }

  next()
})

export default router

<template>
  <div
    class="components common reader"
  >
    <v-row>
      <v-col class="d-flex flex-column align-center justify-center">
        <v-text-field
          v-model="readMode.data"
          outlined
          block
          hide-details="auto"
          :label="$t('reading_data')"
          prepend-inner-icon="fas fa-qrcode"
          ref="input_reader"
          :append-icon="`fas fa-${readMode.type !== 'qrcode' ? 'camera-slash' : 'camera'}`"
          :disabled="httpIsLoading"
          :loading="httpIsLoading"
          style="width: 97%"
          @blur="$refs.input_reader.focus()"
          @click:append="readMode.type === 'code' ? readMode.type = 'qrcode' : readMode.type = 'code'"
          @keyup.enter="readVoucher()"
        />
        <div class="py-2">Total de Leituras: {{ readingCount }}</div>
        <reading-alert
          v-if="readMode.response.message"
          :type="readMode.response.type"
          :text="readMode.response.message"
          :voucher="readMode.response.voucher"
          :response="readMode.response"
        />
      </v-col>
    </v-row>

    <v-row v-show="readMode.type === 'qrcode'" align="center" justify="center">
      <v-col cols="6">
        <qrcode-stream class="camera-wrapper" :camera="cameraMode" @init="initCamera" @decode="onCameraRead"></qrcode-stream>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ComponentsMixins from '@/components/mixins/mixins'
import jsQR from 'jsqr'
import ReadingAlert from '@/components/singleton/ReadingAlert'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'ComponentCommonReader',

  mixins: [
    ComponentsMixins,
  ],

  components: {
    ReadingAlert,
    QrcodeStream,
  },

  data: () => ({
    readMode: {
      type: 'code',
      data: '',
      response: {
        message: '',
        type: null,
        voucher: null,
      }
    },

    // QR Code / Camera
    support: false,
    devices: [],
    device: 0,
    video: null,
    canvasElement: null,
    canvas: null,
    data: '',
    fps: 24,
    stream_started: false,
    settings: false,
    timeout: null,
    memoryLeakWorkaroundTimeout: null,
    cameraMode: 'auto',
  }),

  mounted() {
    this.$refs.input_reader.focus()

    this.startMemoryLeakWorkaround()
    // this.setup()
  },

  methods: {
    async initCamera(promise) {
      try {
        /* eslint-disable no-unused-vars */
        const { capabilities } = await promise
        // successfully initialized
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          console.log('user denied camera access permisson')
        } else if (error.name === 'NotFoundError') {
          console.log('no suitable camera device installed')
        } else if (error.name === 'NotSupportedError') {
          console.log('page is not served over HTTPS (or localhost)')
        } else if (error.name === 'NotReadableError') {
          console.log('maybe camera is already in use')
        } else if (error.name === 'OverconstrainedError') {
          console.log('did you requested the front camera although there is none?')
        } else if (error.name === 'StreamApiNotSupportedError') {
          console.log('browser seems to be lacking features')
        }
      } finally {
        // hide loading indicator
      }
    },
    // Every 15 seconds we're reseting the camera on a interval, this was sugested by a random user
    // on github (see https://github.com/gruhn/vue-qrcode-reader/issues/233).
    startMemoryLeakWorkaround() {
      this.memoryLeakWorkaroundInterval = setInterval(async () => {
        this.cameraMode = 'off'
        await new Promise(r => setTimeout(r, 100));
        this.cameraMode = 'auto'
      }, 15000)
    },
    onCameraRead(data) {
      this.readMode.data = data
      this.readVoucher()
    },
    async emulate() {
      const event = this.$store.state.events.selected
      const vouchers = this.$store.state.vouchers.data[event.id]

      for (const voucher of vouchers) {
        this.readMode.data = voucher.data
        await this.readVoucher();
        await new Promise(r => setTimeout(r, 3000));
      }
    },

    async readVoucher() {
      const data = this.readMode.data

      if (!data) return

      const start = performance.now()

      const client = this.$store.state.clients.selected
      const producer = this.$store.state.producers.selected
      const event = this.$store.state.events.selected
      
      const response = await this.$store.dispatch("reading/read", { client, producer, event, data })

      this.readMode.response.message = response.message
      this.readMode.response.type = response.type
      this.readMode.response.voucher = response.voucher
      this.readMode.response.data = ''

      this.$refs.input_reader.focus()
      
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.readMode.response.message = ''
      }, 5000)

      const end = performance.now()
      
      if (response.voucher) {
        response.voucher.benchmark = end - start
      }

      this.readMode.data = ''
      
      this.$forceUpdate()
    },

    cameraSwitch() {
      if (this.devices.length == 1) {
        return
      }

      this.device++

      if (this.device >= this.devices.length) {
        this.device = 0
      }

      this.play()
    },

    stop() {
      this.video.pause()

      this.video.srcObject.getTracks().forEach((track) => track.stop())

      this.stream_started = false

      this.video.srcObject = null
    },

    async setup() {
      await navigator.mediaDevices.getUserMedia({
        video: true
      })

      if (! navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log('Sem suporte mediaDevices');
        return;
      }

      // devices
      const devices = await navigator.mediaDevices.enumerateDevices()

      this.devices = devices.filter((device) => device.kind === 'videoinput')

      this.video = document.createElement('video')

      this.canvasElement = this.$refs.qrcanvas

      if (this.canvasElement) {
        this.canvas = this.canvasElement.getContext('2d')
      }
    },

    async play() {
      if (! navigator.mediaDevices) {
        console.log('[camera] without support')

        return
      }

      if (window.stream) {
        window.stream.getTracks().forEach(track => {
          track.stop()
        })

        this.stream_started = false
      }

      if (this.stream_started) {
        this.video.play()

        return;
      }

      const constraints = {
        video: {
          width: {
            max: 640,
          },
          height: {
            max: 480,
          },
          frameRate: {
            min: 10,
            max: 30
          },
          deviceId: this.devices[this.device] && this.devices[this.device].deviceId ? { exact: this.devices[this.device].deviceId } : undefined
        }
      }

      window.stream = await navigator.mediaDevices.getUserMedia(constraints)

      this.streamHandler()
    },

    streamHandler() {
      this.video.srcObject = window.stream
      this.video.setAttribute('playsinline', true)
      this.video.play()

      this.stream_started = true

      requestAnimationFrame(this.tick)
    },

    drawLine(begin, end, color, line) {
      this.canvas.beginPath()
      this.canvas.moveTo(begin.x, begin.y)
      this.canvas.lineTo(end.x, end.y)
      this.canvas.lineWidth = line ? line : 4
      this.canvas.strokeStyle = color
      this.canvas.stroke()
    },

    tick() {
      const video = this.video

      const canvasElement = this.canvasElement
      const canvas = this.canvas
      const box_size = 400

      let code = null

      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvasElement.height = box_size
        canvasElement.width = box_size

        const source_x = (video.videoWidth - box_size) / 2
        const source_y = (video.videoHeight - box_size) / 2
        const source_w = box_size
        const source_h = box_size

        canvas.drawImage(video, source_x, source_y, source_w, source_h, 0, 0, box_size, box_size)

        const imageData = canvas.getImageData(0, 0, box_size, box_size)

        // padding
        const padding = 20

        this.canvas.globalAlpha = 0.5
        this.canvas.fillStyle = 'black'
        this.canvas.fillRect(0, 0, box_size, padding)
        this.canvas.fillRect(0, box_size - padding, box_size, padding)
        this.canvas.fillRect(0, padding, padding, box_size - padding * 2)
        this.canvas.fillRect(box_size - padding, padding, padding, box_size - padding * 2)

        // corners
        // top left
        this.drawLine({ x: padding, y: padding }, { x: padding * 2, y: padding }, '#58D68D', 3)
        this.drawLine({ x: padding, y: padding }, { x: padding, y: padding * 2 }, '#58D68D', 3)
        // top right
        this.drawLine({ x: box_size - padding * 2, y: padding }, { x: box_size - padding, y: padding }, '#58D68D', 2)
        this.drawLine({ x: box_size - padding, y: padding }, { x: box_size - padding, y: padding * 2 }, '#58D68D', 2)
        // bottom left
        this.drawLine({ x: padding, y: box_size - padding * 2 }, { x: padding, y: box_size - padding }, '#58D68D', 2)
        this.drawLine({ x: padding, y: box_size - padding }, { x: padding * 2, y: box_size - padding }, '#58D68D', 2)
        // bottom right
        this.drawLine({ x: box_size - padding, y: box_size - padding * 2 }, { x: box_size - padding, y: box_size - padding }, '#58D68D', 2)
        this.drawLine({ x: box_size - padding * 2, y: box_size - padding }, { x: box_size - padding, y: box_size - padding }, '#58D68D', 2)

        if (this.data === '') {
          code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: 'dontInvert'
          })
        }

        if (code) {
          this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, '#FF3B58')
          this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, '#FF3B58')
          this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, '#FF3B58')
          this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, '#FF3B58')

          if (code.data !== '' && code.data != this.data) {
            this.readMode.data = code.data

            this.readVoucher()

            this.data = code.data
          }
        }
      }

      setTimeout(() => {
        requestAnimationFrame(this.tick)
      }, 1000 / this.fps)
    },
  },

  watch: {
    'readMode.type'(value) {
      if (value !== 'qrcode') {
        this.stop()

        return;
      }

      this.play()
    }
  },

  computed: {
    readingCount() {
      if (!this.currentEvent) return 0;

      if (this.history) {
        return this.history.filter((voucher) => voucher.success !== null).length
      }

      return 0
    },
    currentEvent() {
      return this.$store.state.events.selected
    },
    history() {
      return this.$store.state.vouchers.history[this.currentEvent.id];
    }
  }
}
</script>

<style lang="scss">
  .camera-wrapper {
    max-width: 200px;
    aspect-ratio: 1/1;
    border: 4px solid white;
    border-radius: 8px;
  }
</style>

<template>
  <v-snackbar
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
    top
    centered
    :color="color"
    :timeout="timeout"
  >
    {{ message }}
  </v-snackbar>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Snackbar",

  computed: {
    color() {
      return this.snackbar.color;
    },
    message() {
      return this.snackbar.message;
    },
    snackbar() {
      return this.$store.state.snackbar;
    },
    timeout() {
      return this.snackbar.timeout;
    },
    visible: {
      get() {
        return this.snackbar.visible;
      },
      set() {
        this.$store.commit("snackbar/hide");
      },
    },
  },
});
</script>

import axios from 'axios'
import globals from '@/globals'
import router from '@/router/router'
import store from '../store/index'

let clientConfig = {
  withCredentials: true,
  baseURL: globals.api.baseUrl,
  headers: {}
}

const client = axios.create(clientConfig)

client.interceptors.request.use(
  (config) => {

  if(config.headers['Http-Load'] != 'false') {
    store.commit("loading/increase");
  }

  if (store.state.auth.token) {
    config.headers['Authorization'] = store.state.auth.token
  }

  let data = {}

  if (store.state.clients.selected) {
    data.client = store.state.clients.selected.id
  }

  if (store.state.producers.selected) {
    data.producer = store.state.producers.selected.id
  }

  if (store.state.events.selected) {
    data.event = store.state.events.selected.id
  }

  config.headers[globals.application.requestHeaderDataKey] = JSON.stringify(data)

  return config;
  }, 
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    if(response.config.headers['Http-Load'] != 'false') {
      store.commit("loading/decrease");
    }

    return response
  }, 
  (error) => {
    store.commit("loading/decrease");

    if (error.response.status === 401) {
      sessionStorage.setItem('flash_message', JSON.stringify({
        type: 'error',
        message: 'Não autorizado'
      }))

      store.commit("auth/clearToken")

      const regexp = new RegExp(`^/auth`)

      if (! router.history.current.path.match(regexp)) {
        return router.push({
          name: 'auth'
        })
      }
    }

    return Promise.reject(error)
  }
)

export default client

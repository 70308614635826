<template>
  <v-app
    class="app layout default"
  >
    <components-singleton-snackbar/>
    <v-progress-linear
      v-show="httpIsLoading"
      indeterminate
      striped
      color="blue"
      height="5"
      style="position: absolute; z-index: 6"
    />
    <v-app-bar
      app
      color="grey darken-4"
      flat
      dark
    >
      <v-btn
        icon
        @click="drawer.show = ! drawer.show"
      >
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
      <v-toolbar-title
        class="d-flex text-capitalize"
      >
        <span style="font-size: 16px" v-if="producer"> {{ producer.title }} </span>
      </v-toolbar-title>
      <v-spacer />
      <v-icon v-if="$store.getters['reading/selected'] !== undefined" size="18">
        {{ $store.getters['reading/selected'].state.icon }}
      </v-icon>
      <components-settings-language />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer.show"
      app
    >
      <div class="pa-12 drawer-header d-flex align-items-center justify-center">
        <v-img max-width="90" src="@/assets/grupo_dataclick.svg"></v-img>
      </div>
      <v-divider />
      <v-list-item v-if="user">
        <v-list-item-action>
          <v-icon>
            fas fa-user
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('greetings') }}, {{ user.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider/>
      <v-list
        class="pt-0"
      >
        <div
          v-for="item in drawerItems"
          :key="item.title"
        >
          <v-list-group
            v-if="item.items"
            :prepend-icon="item.icon"
            color="white"
            no-action
          >
            <template
              v-slot:activator
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="$tc(item.title, 2)"
                />
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="child in item.items"
              exact
              :key="child.title"
              :to="{
                name: child.toRouteName
              }"
            >

              <v-list-item-title
                v-text="$tc(child.title, 2)"
              />
              <v-list-item-icon>
                <v-icon
                  v-text="child.icon"
                />
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            exact
            :key="item.title"
            :to="{
              name: item.toRouteName
            }"
          >
            <v-list-item-action>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $tc(item.title, 2) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-divider />
        <v-list-item @click="$router.push({name: 'auth_logout'})">
          <v-list-item-action>
            <v-icon>
              fas fa-right-from-bracket
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('logout') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <layouts-partials-footer />
      </template>
    </v-navigation-drawer>
    <v-main>
      <slot/>
      <v-spacer></v-spacer>
      <layouts-partials-footer />
    </v-main>
  </v-app>
</template>

<script>
import ComponentsSettingsLanguage from '@/components/settings/Language'
import ComponentsSingletonSnackbar from '@/components/singleton/Snackbar'
import LayoutsMixins from '@/layouts/mixins/mixins'
import LayoutsPartialsFooter from '@/layouts/partials/Footer'

export default {
  name: `LayoutDefault`,

  mixins: [
    LayoutsMixins
  ],

  components: {
    ComponentsSettingsLanguage,
    ComponentsSingletonSnackbar,
    LayoutsPartialsFooter
  },

  data() {
    return {
      drawer: {
        show: false,
        items: {}
      },
    }
  },

  mounted() {
    // Simulate fetching user data.
    if (this.user === null) {
      this.getUser()
    }
  },

  methods: {
    async getUser() {
      const response = await this.$http.get('/api/client/user')
      this.$store.commit("user/set", response.data)
    }
  },

  props: {
    drawerItems: {
      required: false,
      type: Array,
      default() {
        return []
      } 
    }
  },

  computed: {
    producer() {
      return this.$store.state.producers.selected
    },
    user() {
      return this.$store.state.user.data
    }
  }
}
</script>

<style lang="scss">
.app {
  &.layout {
    &.default {
      .drawer-header {
        background-color: rgba(255, 255, 255, 0.1)
      }
    }
  }
}
</style>

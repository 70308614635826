const state = {
  data: null,
}

const mutations = {
  set(_state, clients) {
    _state.data = clients
  }
}

const actions = {}

export const store = {
  namespaced: true,
  state,
  mutations,
  actions
}
export default {
  getAuth() {
    let auth = localStorage.getItem('auth')

    if (! auth) {
      return {
        token: null
      }
    }
    // return { token: 'abc' }
    return JSON.parse(auth)
  },

  setAuth(token) {
    const auth = {
      token
    }

    localStorage.setItem('auth', JSON.stringify(auth))
  },

  isLogged() {
    const auth = this.getAuth()

    if (! auth.token) {
      return false
    }

    return true
  }
}

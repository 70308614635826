import http from '../plugins/axios';

export default {
  async index(client, producer) {
    const response = await http.get(`/api/client/${client.id}/producer/${producer.id}/event`)
    
    return response.data
  },
  async export(client, producer, event, vouchers, sections) {
    const response = await http.post(`/api/client/${client.id}/producer/${producer.id}/event/${event.id}/export`,
      { 
        vouchers: vouchers,
        sections
      }
    )

    return response.data;
  }
}
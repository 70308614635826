export default {
  getModule() {
    return this.state.module
  },

  setModule(module) {
    this.state.module = module
  },

  getModuleActive() {
    return this.state.module.active
  },

  setModuleActive(module) {
    this.state.module.active = module
  },

  getModuleActiveIcon() {
    return this.state.module.items[this.getModuleActive()].icon
  },

  getModuleActiveTitle() {
    return this.state.module.items[this.getModuleActive()].title
  },

  getModuleActiveMenu() {
    return this.state.module.items[this.getModuleActive()].menu
  }
}

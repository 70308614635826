<template>
  <div class="components module event-info" >
    <v-card
      flat
      color="transparent"
      class="mb-4 mt-4"
    >
      <v-card-text>
        <v-row>
          <v-col
            v-if="showFinish"
            class="pa-0"
            cols="12"
          >
            <v-btn
              block
              outlined
              x-large
              :loading="httpIsLoading"
              @click="dialog_finish = true"
            >
              {{ $t('events_finish') }}
              
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialog_finish"
      persistent
      width="320"
      :retain-focus="false"
    >
      <v-card>
        <v-card-title
          class="text-h5"
        >
          {{ $t('events_finish') }}
        </v-card-title>
        <v-card-text>
          {{ $t('events_finish_warn') }}
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="grey"
            :loading="httpIsLoading"
            @click="dialog_finish = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            text
            color="green darken-1"
            :loading="httpIsLoading"
            @click="finish()"
          >
            {{ $t('finish') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ComponentsMixins from '@/components/mixins/mixins'

export default {
  name: 'ComponentsModuleEventInfo',

  mixins: [
    ComponentsMixins
  ],

  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    showFinish: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    error: null,
    dialog_finish: false
  }),

  methods: {
    async finish() {
      this.error = null

      try {
        await this.$store.dispatch("events/finish");

        this.$store.commit("events/unselect");

        sessionStorage.setItem('flash_message', JSON.stringify({
          type: 'success',
          message: 'events_finished_success'
        }))

        this.$snackbar('Leitura finalizada com sucesso', 'success')

        this.$router.push({
          name: 'event_index'
        })
      } catch (error) {
        console.log(error)
        this.error = 'events_error_cant_finish'
        
        this.$snackbar('Ocorreu um erro ao tentar finalizar a leitura', 'error')
        
        this.$store.dispatch(
          "logging/add", 
          { 
            message: `Erro durante a exportação do evento ${this.$store.state.events.selected} códigos. Erro: ${error}`
          }
        )
      } finally {
        this.dialog_finish = false
      }
    }
  },

  computed: {
    currentSections() {
      return this.$store.state.sectors.selected
    }
  }
}
</script>

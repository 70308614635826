import methodsAuth from "@/store/methods/auth"
import methodsUser from "@/store/methods/user"
import methodsClients from "@/store/methods/clients"
import methodsClientsProducers from "@/store/methods/clientsProducers"
import methodsClientsProducersEvents from "@/store/methods/clientsProducersEvents"
import methodsClientsProducersEventsLocal from "@/store/methods/clientsProducersEventsLocal"
import methodsClientsProducersEventsVouchers from "@/store/methods/clientsProducersEventsVouchers"
import methodsClientsProducersEventsVouchersHistory from "@/store/methods/clientsProducersEventsVouchersHistory"
import methodsHttp from "@/store/methods/http"
import methodsModule from "@/store/methods/module"
import methodsNetworkAddress from "@/store/methods/networkAddress"
import methodsSelected from "@/store/methods/selected"
import state from "@/store/state"

export default {
  state,
  ...methodsAuth,
  ...methodsUser,
  ...methodsClients,
  ...methodsClientsProducers,
  ...methodsClientsProducersEvents,
  ...methodsClientsProducersEventsLocal,
  ...methodsClientsProducersEventsVouchers,
  ...methodsClientsProducersEventsVouchersHistory,
  ...methodsHttp,
  ...methodsModule,
  ...methodsNetworkAddress,
  ...methodsSelected
}


<template>
  <layout-default>
    <div
      class="views offline section"
    >
      <v-container>
        <h1
        class="mb-4"
        >
          Carga - Exportar
        </h1>
        <v-row>
          <v-col>
            <v-btn
              block
              outlined
              @click="voucherExport()"
            >
              Exportar
              <v-icon
                right
                small
              >
                mdi-database-export
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/Default'

export default {
  name: `OfflineExport`,

  components: {
    LayoutDefault
  },
  
  data: () => ({
    //
  }),

  mounted() {
    //
  },
  
  methods: {
    voucherExport() {
      this.$router.push({
        name: 'offline_index'
      })
    }
  }
}
</script>

<style lang="scss">
.views {
  &.offline {
    &.export {
      //
    }
  }
}

@media screen and (max-width: 600px) {
  .views {
    &.offline {
      &.export {
        //
      }
    }
  }
}
</style>

<template>
  <layout-default :drawer-items="drawer.items">
    <div class="views network configuration">
      <v-container>
        <info-header/>
        <v-row no-gutters>
          <v-col class="window">
            <v-card color="transparent" flat>
              <v-card-text class="mb-4">
                <v-row>
                  <v-col>
                    <v-form
                      ref="views_network_configuration_form"
                      v-model="form.valid"
                      lazy-validation
                    >
                      <components-common-flash-message />
                      <v-alert
                        v-show="error"
                        dark
                        dense
                        prominent
                        color="red"
                        type="error"
                      >
                        {{ $t(error) }}
                      </v-alert>
                      <v-row>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="form.fields.address.value"
                            outlined
                            hide-details="auto"
                            prepend-inner-icon="mdi-network"
                            :label="$t('network_local_server')"
                            :rules="[
                              form.fields.address.rules.required,
                              form.fields.address.rules.url
                            ]"
                          >
                            <template v-slot:message="slotProps">
                              {{ $t(slotProps.message) }}
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-btn
                            block
                            color="primary"
                            x-large
                            :loading="loading.save"
                            @click="save()"
                          >
                            {{ $t('choose') }}
                          </v-btn>
                          <v-btn
                            block
                            x-large
                            color="secondary"
                            :loading="httpIsLoading"
                            @click="$router.push('/')"
                            class="mt-6"
                          >
                            {{ $t('return') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </layout-default>
</template>

<script>
import ComponentsCommonFlashMessage from '@/components/common/FlashMessage'
import LayoutDefault from '@/layouts/Default'
import InfoHeader from '@/layouts/partials/InfoHeader'
import store from '@/store/store'

export default {
  name: 'NetworkConfiguration',

  components: {
    ComponentsCommonFlashMessage,
    LayoutDefault,
    InfoHeader
  },
  
  data: () => ({
    error: null,

    loading: {
      save: false
    },

    form: {
      valid: false,
      fields: {
        address: {
          value: store.getNetworkAddress() || 'http://localhost',
          rules: {
            required: value => !! value || 'network_error_point_address',
            url: value => /^(http|https):\/\//i.test(value) || 'error_invalid_url'
          }
        }
      }
    },

    drawer: {
      items: [
        {
          icon: 'fa-solid fa-shield-halved',
          title: 'producers',
          toRouteName: 'producer_index'
        },
        {
          icon: 'fa-solid fa-calendar-days',
          title: 'events',
          toRouteName: 'event_index'
        },
        {
          icon: 'mdi-barcode-scan',
          title: 'reading',
          toRouteName: 'index'
        },
      ]
    }
  }),
  
  methods: {
    async save() {
      this.error = null

      if (! this.$refs.views_network_configuration_form.validate()) {
        return
      }

      this.loading.save = true

      const address = `${this.form.fields.address.value}/api/ping`

      await this.$http.get(address)
      .then(() => {
        this.$store.commit('reading/network/setConfig', { address: this.form.fields.address.value })

        this.$http.defaults.baseUrl = this.form.fields.address.value

        this.$router.push({
          name: 'network_index'
        })
      })
      .catch(() => {
        this.error = 'network_error_invalid_or_unreachable_address'
      })
      .finally(() => {
        this.$store.commit('reading/network/setConfig', { address: this.form.fields.address.value })

        this.$http.defaults.baseUrl = this.form.fields.address.value

        this.$router.push({
          name: 'network_index'
        })
        
        this.loading.save = false
      })
    }
  }
}
</script>

<style lang="scss">
.views {
  &.network {
    &.configuration {
      //
    }
  }
}

@media screen and (max-width: 600px) {
  .views {
    &.network {
      &.configuration {
        //
      }
    }
  }
}
</style>

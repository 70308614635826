import http from '@/plugins/axios'

const state = {
  route: 'online',
  icon: 'fas fa-wifi',
  title: 'online',
}

const actions = {
  async read(context, { client, producer, event, data }) {
    // Fallback handler for disconnected devices
    if (!window.navigator.onLine) {
      return {
        message: 'reading_error_no_connection',
        type: 'error',
        voucher: null,
        data: ''
      }
    }

    try {
      const response = await http.post(
        `/api/client/${client.id}/producer/${producer.id}/event/${event.id}/voucher`, 
        {
          data,
          sections: context.rootState.sectors.selected.map(item => item.id)
        }
      )
      
      return {
        message: 'reading_success',
        type: 'success',
        voucher: response.data,
        data: ''
      }
    } catch (error) {
      return {
        type: 'error',
        error: error.response
      }
    }
  }
}

export const store = {
  namespaced: true,
  state,
  actions
}
<template>
  <layout-default :drawer-items="drawer.items">
    <layouts-partials-info-header :title="'logs'" :showSections="false" :showEvent="false"/>
    <v-simple-table class="logs-table mx-4" style="font-size: 12px">
      <tr>
        <th> {{ $t('timestamp') }} </th>
        <th> {{ $t('message') }} </th>
      </tr>
      <tr v-for="(log, index) in logs" :key="index">
        <td class="text-center"> {{ log.timestamp }} </td>
        <td> {{ log.message }} </td>
      </tr>
    </v-simple-table>
  </layout-default>
</template>

<script>
import Vue from "vue";
import LayoutDefault from '@/layouts/Default'
import LayoutsPartialsInfoHeader from '@/layouts/partials/InfoHeader'

export default Vue.extend({
  data() {
    return {
      drawer: {
        items: [
          {
            icon: 'fa-solid fa-shield-halved',
            title: 'producers',
            toRouteName: 'producer_index'
          },
          {
            icon: 'fa-solid fa-calendar-days',
            title: 'events',
            toRouteName: 'event_index'
          },
          {
            icon: 'mdi-barcode-scan',
            title: 'reading',
            toRouteName: 'index'
          },
          {
            icon: 'fa-solid fa-grid-2',
            title: 'sections',
            toRouteName: 'offline_section'
          },
          {
            icon: 'mdi-chart-line',
            title: 'reports',
            toRouteName: 'report'
          },
          {
            icon: 'mdi-database',
            title: 'cargo',
            items: [
              {
                icon: 'mdi-database-cog',
                title: 'manage',
                toRouteName: 'offline_manage'
              }
            ]
          }
        ]
      }
    }
  },
  components: {
    LayoutDefault,
    LayoutsPartialsInfoHeader
  },
  computed: {
    logs() {
      return this.$store.state.logging.stored;
    }
  }
});
</script>

<style>
  .logs-table {
    background-color: transparent !important;
  }

  .logs-table table,
  .logs-table td,
  .logs-table tr {
    background-color: transparent !important;
    border: 1px dashed #ccc;
    border-collapse: collapse;
  }

  .logs-table td {
    height: 30px !important;
    padding: 6px;
  }
</style>
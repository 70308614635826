export default {
  getSelected() {
    return JSON.parse(localStorage.getItem('selected')) || {}
  },

  getSelectedByKey(key) {
    if (this.state.selected[key] && Object.entries(this.state.selected[key]).length > 0) {
      return this.state.selected[key]
    }
    
    const selected = JSON.parse(localStorage.getItem('selected'))

    if (selected && selected[key]) {
      return selected[key]
    }
    
    return null
  },

  setSelectedByKey(key, value) {
    let selected = JSON.parse(localStorage.getItem('selected'))

    if (! selected) {
      selected = {}
    }

    selected[key] = value

    localStorage.setItem('selected', JSON.stringify(selected))

    this.state.selected = selected
  },

  clearSelected(keys) {
    const selected = this.getSelected()

    keys.map(key => {
      if (selected[key]) {
        selected[key] = null
      }
    })
    
    localStorage.setItem('selected', JSON.stringify(selected))

    this.state.selected = selected
  }
}

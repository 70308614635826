<template>
  <div>
    <v-alert
      v-if="flashMessage.message"
      :type="flashMessage.type"
    >
      {{ $t(flashMessage.message) }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'ComponentsCommonFlashMessage',

  data: () => ({
    flashMessage: {}
  }),

  mounted() {
    const flashMessage = sessionStorage.getItem('flash_message')

    if (flashMessage) {
      this.flashMessage = JSON.parse(flashMessage)

      sessionStorage.removeItem('flash_message')
    }
  }
}
</script>

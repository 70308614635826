// GLOBAL MIXINS
import store from '@/store/index'

export default {
  computed: {
    httpIsLoading() {
      return store.state.loading.counter !== 0;
    },

    isOffline() {
      return navigator.onLine
    }
  }
}

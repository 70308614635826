import router from '@/router/router'
import store from '../../store/index'

export default {
  authorization() {
    if (!store.state.auth.token) {
      router.push({
        name: 'auth'
      })

      return false
    }

    return true
  },

  moduleAuthorization() {
    if (! this.moduleAuthorizationClient()) {
      return false
    }

    if (! this.moduleAuthorizationProducer()) {
      return false
    }

    if (! this.moduleAuthorizationEvent()) {
      return false
    }

    return true
  },

  moduleAuthorizationClient() {
    const client = store.state.clients.selected

    if (! client || ! client.id) {
      sessionStorage.setItem('flash_message', JSON.stringify({
        type: 'error',
        message: 'module_client_error_undefined'
      }))

      router.push({
        name: 'auth_index'
      })

      return false
    }

    return true
  },

  moduleAuthorizationProducer() {
    const producer = store.state.producers.selected

    if (! producer || ! producer.id) {
      sessionStorage.setItem('flash_message', JSON.stringify({
        type: 'error',
        message: 'module_producer_error_undefined'
      }))

      router.push({
        name: 'producer_index'
      })

      return false
    }

    return true
  },

  moduleAuthorizationEvent() {
    const event = store.state.events.selected

    if (! event || ! event.id) {
      sessionStorage.setItem('flash_message', JSON.stringify({
        type: 'error',
        message: 'module_event_error_undefined'
      }))

      router.push({
        name: 'event_index'
      })

      return false
    }

    return true
  }
}

const state = {
  counter: 0,
}

const mutations = {
  increase(_state) {
    _state.counter += 1;
  },
  decrease(_state) {
    _state.counter = Math.max(_state.counter - 1, 0);
  },
  clear(_state) {
    _state.counter = 0;
  },
}

const actions = {}

export const store = {
  namespaced: true,
  state,
  mutations,
  actions
}
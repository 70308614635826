<template>
  <v-snackbar
    v-model="visible"
    :timeout="timeout"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="blue"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'ComponentCommonNotification',

  data: () => ({
    visible: false,
    timeout: 3000,
    message: ''
  }),

  mounted() {
    this.$root.$on('notification', (notification) => {
      this.visible = notification.visible || true
      this.visible = notification.timeout || this.timeout
      this.message = notification.message || this.message
    })
  }
}
</script>


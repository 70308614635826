<template>
  <layout-default :drawer-items="drawer.items">
    <div
      class="views offline section"
    >
      <v-container>
        <info-header/>
        <v-row no-gutters>
          <v-col class="window">
            <v-card color="transparent" flat>
              <v-card-text class="mb-4">
                <v-row>
                  <v-col>
                    <components-module-section
                      :redirectOnSave="{
                        name: 'offline_index'
                      }"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </layout-default>
</template>

<script>
import ComponentsModuleSection from '@/components/module/Section'
import LayoutDefault from '@/layouts/Default'
import InfoHeader from '@/layouts/partials/InfoHeader'

export default {
  name: `OnlineSection`,

  components: {
    ComponentsModuleSection,
    LayoutDefault,
    InfoHeader
  },
  
  data: () => ({
    drawer: {
      items: [
        {
          icon: 'fa-solid fa-shield-halved',
          title: 'producers',
          toRouteName: 'producer_index'
        },
        {
          icon: 'fa-solid fa-calendar-days',
          title: 'events',
          toRouteName: 'event_index'
        },
        {
          icon: 'mdi-barcode-scan',
          title: 'reading',
          toRouteName: 'index'
        },
      ]
    }
  }),

  mounted() {
    //
  },
  
  methods: {
    //
  }
}
</script>

<style lang="scss">
.views {
  &.offline {
    &.section {
      //
    }
  }
}

@media screen and (max-width: 600px) {
  .views {
    &.offline {
      &.section {
        //
      }
    }
  }
}
</style>

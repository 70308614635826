import ProducerService from "../../services/ProducerService";

const state = {
  data: [],
  selected: null,
}

const mutations = {
  set(_state, data) {
    _state.data = data;
  },
  select(_state, data) {
    _state.selected = data;
  },
  clear(_state) {
    _state.data = [];
    _state.selected = null;
  },
}

const actions = {
  async fetch(context) {
    try {
      const client = context.rootState.clients.selected
      const producers = await ProducerService.index(client)
      context.commit("set", producers)
    } catch (error) {
      context.commit(
        'snackbar/show', 
        {
          message: 'error_reading_clients',
          timeout: 5000,
          color: 'error'
        },
        { root: true }
      )
    }
  }
}

export const store = {
  namespaced: true,
  state,
  mutations,
  actions
}
<template>
  <layout-default
    :showQuickConfiguration="false"
    :drawer-items="drawer.items"
  >
    <div class="views index d-flex flex-column justify-start align-center">
      <v-container>
        <info-header/>
        <v-row no-gutters class="justify-center">
          <v-col class="window">
            <v-card flat color="transparent">
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col class="d-flex justify-center align-center">
                    <span class="white--text">{{$t('reading_mode')}}</span>
                  </v-col>
                </v-row>
                <v-list color="transparent">
                  <v-list-item-group active-class="selected-item">
                    <v-list-item @click="change(module)" style="border: 2px solid white; border-radius: 4px" class="my-2" v-for="(module, moduleName) in modules" v-bind:key="moduleName">
                      <v-list-item-content>
                        <v-col>
                          {{ $t(module.title) }}
                        </v-col>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon>
                          {{ module.icon }}
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-btn
                  block
                  x-large
                  color="primary"
                  @click="select()"
                >
                  {{ $t('choose') }}
                </v-btn>
                <v-btn
                  block
                  x-large
                  color="secondary"
                  @click="$router.push({name: 'event_index'})"
                  class="mt-6"
                >
                  {{ $t('return') }}
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/Default'
import InfoHeader from '@/layouts/partials/InfoHeader'

export default {
  name: `Index`,

  components: {
    LayoutDefault,
    InfoHeader
  },

  data: () => ({
    selection: null,
    drawer: {
      items: [
        {
          icon: 'fa-solid fa-shield-halved',
          title: 'producers',
          toRouteName: 'producer_index'
        },
        {
          icon: 'fa-solid fa-calendar-days',
          title: 'events',
          toRouteName: 'event_index'
        },
        {
          icon: 'mdi-math-log',
          title: 'logs',
          toRouteName: 'logs'
        },
      ]
    }
  }),

  computed: {
    modules() {
      return this.$store.getters["reading/modules"]
    }
  },

  methods: {
    select() {
      this.$router.push({
        name: this.selection.route
      })
    },
    change(module) {
      this.selection = module
    }
  },

  mounted() {
    this.$store.commit('reading/network/setConfig', { address: '' })
  }
}
</script>

<style lang="scss">

.selected-item {
  border: 2px solid orange !important;
  caret-color: rgb(0,0,0) !important;
}
</style>

<template>
  <layout-default :drawer-items="drawer.items">
    <div class="views producer index d-flex flex-column justify-start align-center">
      <v-container>
        <info-header/>
        <v-row no-gutters class="justify-center">
          <v-col class="window">
            <v-card color="transparent" flat class="common bg-alpha-dark-01">
              <v-card-text>
                <v-form
                  ref="views_event_index_form"
                  v-model="form.valid"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="form.fields.event.value"
                        outlined
                        hide-details="auto"
                        item-text="title"
                        item-value="id"
                        :items="sortedEvents"
                        :label="$t('events_choose')"
                        :loading="httpIsLoading"
                        :no-data-text="$t('no_data')"
                        :rules="[form.fields.event.rules.required]"
                      >
                        <template v-slot:message="slotProps">
                          {{ $t(slotProps.message) }}
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-col>
                              <div>
                                {{ item.title }}
                              </div>
                              <small>
                                {{ item.type}}
                              </small>
                              <div>
                                <small class="primary--text">
                                  {{ item.calendar.date }}
                                </small>
                              </div>
                            </v-col>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-btn
                        block
                        x-large
                        color="primary"
                        :loading="httpIsLoading"
                        @click="select()"
                      >
                        {{ $t('choose') }}
                      </v-btn>
                      <v-btn
                        block
                        x-large
                        color="secondary"
                        :loading="httpIsLoading"
                        @click="$router.go(-1)"
                        class="mt-6"
                      >
                        {{ $t('return') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/Default'
import InfoHeader from '@/layouts/partials/InfoHeader'
import ViewsMixins from '@/views/mixins/mixins'

export default {
  name: 'EventIndex',

  mixins: [
    ViewsMixins
  ],

  components: {
    LayoutDefault,
    InfoHeader
  },

  data: () => ({
    form: {
      valid: false,
      fields: {
        event: {
          rules: {
            required: value => !! value || 'events_choose'
          }
        }
      }
    },
    drawer: {
      items: [
        {
          icon: 'fa-solid fa-shield-halved',
          title: 'producers',
          toRouteName: 'producer_index'
        },
        {
          icon: 'mdi-database',
          title: 'cargo',
          items: [
            {
              icon: 'mdi-database-cog',
              title: 'manage',
              toRouteName: 'offline_manage'
            }
          ]
        },
        {
          icon: 'mdi-math-log',
          title: 'logs',
          toRouteName: 'logs'
        },
      ]
    }
  }),

  mounted() {
    this.$store.commit('events/clear', null, { root: true });
    this.$store.commit('sectors/clear', null, { root: true });
    this.$store.commit('reading/setActiveModule', '')
    this.fetch()
  },

  methods: {
    async fetch() {
      this.$store.dispatch("events/fetch")
    },

    async select() {
      const event = this.events.find(item => String(item.id) === String(this.form.fields.event.value))
      const producer = this.$store.state.producers.selected;

      if (!this.$refs.views_event_index_form.validate()) {
        return
      }

      this.$store.commit("events/select", event)
      this.$store.commit('sectors/clear', null, { root: true });
      this.$store.dispatch("logging/add", { message: `Evento ${event.id} do produtor ${producer.id} selecionado`})

      // store.setClientsProducersEventsLocal()
      // console.log(store.getClientsProducersEventsLocal())

      const imported = await this.$store.dispatch("vouchers/fetch")
      
      if (imported !== 0) {
        this.$snackbar(
          `Foram importados ${imported} códigos`,
          "success",
          3000,
        )
        this.$store.dispatch("logging/add", { message: `Foram importados ${imported} códigos novos do evento ${event.id} do produtor ${producer.id}`})
      } else {
        this.$snackbar(
          "Nenhum código novo foi importado",
          "success"
        )
        this.$store.dispatch("logging/add", { message: `Nenhum código novo foi importado do evento ${event.id} do produtor ${producer.id}`})
      }

      this.$router.push({
        name: 'index'
      })
    }
  },

  computed: {
    events() {
      return this.$store.state.events.data
    },
    sortedEvents() {
      return this.events.toSorted((a, b) => {
        if (a > b) return 1
        else return -1
      })
    }
  }
}
</script>

<style lang="scss">
.views {
  &.event {
    &.index {
      .window {
        max-width: 320px;
      }
    }
  }
}
</style>

<template>
  <v-menu
    close-on-click
    offset-y
    nudge-bottom="6"
  >
    <template
      v-slot:activator="{ on, attrs }"
    >
      <v-btn
        dark
        small
        v-bind="attrs"
        v-on="on"
        elevation="0"
        color="transparent"
        style="font-size: 12px"
        class="mr-n4"
      >
        <v-row>
          <v-col class="d-flex align-center">
            <v-img class="mr-2" max-width="25" :src="require(`@/assets/flags/${languageFlag}.png`)"></v-img>
            {{ languagesFiltered.abbrev }}
            <v-icon> mdi-menu-down </v-icon>
          </v-col>
        </v-row>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="item in languages"
        :key="item.value"
        dense
        :input-value="languageFlag === item.icon"
        @click="changeLanguage(item.value)"
      >
        <v-list-item-title class="d-flex align-center">
          <v-img style="display: inline-block" class="mr-2" max-width="20" :src="require(`@/assets/flags/${item.icon}.png`)"></v-img>
          {{ item.abbrev }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import locales from '@/locales/locales'
import i18n from '@/plugins/i18n'
import store from '@/store/store'

export default {
  name: 'ComponentsSettingsLanguage',

  data: () => ({
    //
  }),

  computed: {
    languages() {
      let languages = []

      for (const locale in locales) {
        languages.push({
          value: locale,
          text: locales[locale]._name,
          icon: locales[locale]._icon,
          abbrev: locales[locale]._abbrev,
        })
      }
      
      return languages
    },

    languagesFiltered() {
      const language = this.$store.state.i18n.locale
      return this.languages.find(item => item.value === language)
    },

    languageFlag() {
      if (this.languagesFiltered) {
        return this.languagesFiltered.icon
      }

      return ''
    }
  },

  methods: {
    changeLanguage(value) {
      i18n.locale = value

      store.setLanguage(value)
    },
  }
}
</script>

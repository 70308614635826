<template>
  <layout-default>
    <div
      class="views offline section"
    >
      <v-container>
        <h1
        class="mb-4"
        >
          Carga - Importar
        </h1>
        <v-row>
          <v-col>
            <v-select
              v-model="eventExport"
              dense
              outlined
              hide-details="auto"
              label="Selecione um evento"
              item-text="title"
              item-value="id"
              :no-data-text="$t('no_data')"
              :items="events"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              block
              outlined
              @click="voucherImport()"
            >
              Importar
              <v-icon
                right
                small
              >
                mdi-database-import
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/Default'
import store from '@/store/store'

export default {
  name: `OfflineImport`,

  components: {
    LayoutDefault
  },
  
  data: () => ({
    eventExport: null
  }),

  mounted() {
    //
  },
  
  methods: {
    voucherImport() {
      this.$router.push({
        name: 'offline_index'
      })
    }
  },

  computed: {
    events() {
      return store.getClientsProducersEvents()
    }
  }
}
</script>

<style lang="scss">
.views {
  &.offline {
    &.export {
      //
    }
  }
}

@media screen and (max-width: 600px) {
  .views {
    &.offline {
      &.export {
        //
      }
    }
  }
}
</style>

<template>
  <layout-default :drawer-items="drawer.items">
    <div class="views producer index d-flex flex-column justify-start align-center">
      <v-container>
        <info-header/>
        <v-row no-gutters class="justify-center">
          <v-col class="window">
            <v-card
              flat
              class="common bg-alpha-dark-01"
              color="transparent"
            >
              <v-card-text>
                <v-form
                  ref="views_producer_index_form"
                  v-model="form.valid"
                  lazy-validation
                >       
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="form.fields.producer.value"
                        outlined
                        hide-details="auto"
                        item-text="title"
                        item-value="id"
                        :items="producers"
                        :label="$t('producers_choose')"
                        :loading="httpIsLoading"
                        :no-data-text="$t('no_data')"
                        :rules="[form.fields.producer.rules.required]"
                      >
                        <template v-slot:message="slotProps">
                          {{ $t(slotProps.message) }}
                        </template>
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-btn
                        block
                        x-large
                        color="primary"
                        :loading="httpIsLoading"
                        @click="select()"
                      >
                        {{ $t('choose') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/Default'
import InfoHeader from '@/layouts/partials/InfoHeader'
import ViewsMixins from '@/views/mixins/mixins'

export default {
  name: 'ProducerIndex',

  mixins: [
    ViewsMixins
  ],


  components: {
    LayoutDefault,
    InfoHeader
  },
  
  data: () => ({
    form: {
      valid: false,
      fields: {
        producer: {
          rules: {
            required: value => !! value || 'validation_producers_select'
          }
        }
      }
    },
    drawer: {
      items: []
    }
  }),

  created() {
    this.$store.commit('producers/clear', null, { root: true });
    this.$store.commit('events/clear', null, { root: true });
    this.$store.commit('sectors/clear', null, { root: true });
    this.$store.commit('reading/setActiveModule', '')
    this.fetch()
  },
  
  methods: {
    async fetch() {
      this.$store.dispatch('producers/fetch');
    },

    select() {
      const producer = this.producers.find(item => String(item.id) === String(this.form.fields.producer.value))

      if (!this.$refs.views_producer_index_form.validate()) {
        return
      }

      this.$store.commit('producers/select', producer);
      this.$store.commit('events/clear', null, { root: true });
      this.$store.commit('sectors/clear', null, { root: true });
      
      this.$router.push({
        name: 'event_index'
      })
    }
  },

  computed: {
    producers() {
      return [...this.$store.state.producers.data].sort((a, b) => {
        if (a.title > b.title) return 1
        if (a.title < b.title) return -1
        return 0
      })
    }
  }
}
</script>

<style lang="scss">
.views {
  &.producer {
    &.index {
      .window {
        max-width: 320px;
      }
    }
  }
}
</style>

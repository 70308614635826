export default {
  'en-US': {
    _name: 'English',
    _icon: 'us',
    _abbrev: 'ENG',
    version: 'Version',
    authentication: 'Authentication',
    application_subtitle: 'Access Control',
    report_subtitle: 'Access Report',
    client_application: 'Platform',
    invalid_credentials: 'Invalid credentials',
    clients: 'Clients | Client',
    disconnected: 'Disconnected',
    save: 'Save',
    user: 'User',
    password: 'Password',
    login: 'Login',
    logout: 'Logout',
    choose: 'Choose',
    return: 'Go back',
    no_data: 'No data avaliable',
    cancel: 'Cancel',
    remove: 'Remove',
    finish: 'Finish',
    sort_by: 'Sort by',
    executions: 'Executions | Execution',
    totals: 'Totals | Total',
    error_session_expired: 'Sesssion expired',
    error_invalid_url: 'Invalid URL',
    configurations: 'Configurations',
    manage: 'Manage',
    cargo: 'Local data',
    greetings: 'Hello',
    producers: 'Producers | Producer',
    producers_choose: 'Choose a producer',
    events: 'Events | Event',
    events_choose: 'Choose an event',
    events_active: 'Active events',
    events_finished: 'Finished events',
    events_finished_success: 'Event ended successfully!',
    events_finish: 'Finish event',
    events_finish_warn: 'Do you really want to finish this event?<br /><br />This action exports the event data and marks it as finished.',
    event_data_deletion: 'Event remove',
    event_data_deletion_warn: 'Do you really want to remove this event (on this device only)?<br /><br />This action will be irreversible.',
    events_error_cant_finish: 'Unable to finalize/export event',
    reports: 'Reports | Report',
    online: 'On-line',
    offline: 'Off-line',
    network: 'Network',
    network_local_server: 'Local read server',
    network_error_point_address: 'Set the network address',
    network_error_invalid_or_unreachable_address: 'Invalid or unreachable address',
    network_info_undefined: 'Set the network address before taking readings',
    module_client_error_undefined: 'No client/application currently selected/active',
    module_producer_error_undefined: 'No producers currently selected/active',
    module_event_error_undefined: 'No events currently selected/active',
    reading: 'Reading',
    reading_mode: 'Read mode',
    reading_data: 'Read data',
    reading_none: 'No reading taken',
    reading_success: 'Voucher read successfully',
    reading_total_success: 'Total successful reads',
    reading_last_success: 'Last successful readings',
    reading_error_voucher_already_read: 'Voucher has already been read/processed',
    reading_error_voucher_forbidden_in_section: 'Voucher is not enabled in this section',
    reading_error_voucher_not_found_or_invalid: 'Voucher not found or invalid',
    reading_error_voucher_forbidden: 'Voucher is not allowed',
    sections: 'Sections',
    sections_in_use: 'Sections in use',
    sections_choose: 'Choose a section',
    sections_error_choose_at_least: 'Choose at least 1 section',
    sections_info_undefined: 'Set sections before reading',
    validation_producers_select: 'Choose a producer',
    event_date: 'Event date',
    event_start_hour: 'Event hour',
    event_opening_hour: 'Gate opening',
    event_ending_hour: 'Gate closing',
    readings_by_status: 'Readings by status',
    readings_by_sections: 'Readings by sections',
    readings_by_types: 'Readings by type',
  },
}

<template>
  <layout-default :drawer-items="drawer.items">
    <div
      class="views offline index"
    >
      <v-container>
        <info-header/>
        <components-common-reader />
        <v-divider/>
        <components-common-voucher-history />
        <components-module-event-finish :showFinish="true" :event="event" />
      </v-container>
    </div>
  </layout-default>
</template>

<script>
import ComponentsCommonReader from '@/components/common/Reader'
import ComponentsCommonVoucherHistory from '@/components/common/VoucherHistory'
import ComponentsModuleEventFinish from '@/components/module/EventFinish'
import LayoutDefault from '@/layouts/Default'
import InfoHeader from '@/layouts/partials/InfoHeader'
import RouterGuardsOffline from '@/router/guards/offline'

export default {
  name: `OfflineIndex`,

  components: {
    ComponentsCommonReader,
    ComponentsCommonVoucherHistory,
    ComponentsModuleEventFinish,
    LayoutDefault,
    InfoHeader,
  },

  created() {
    RouterGuardsOffline.indexAuthorization()
  },
  
  data: () => ({
    drawer: {
      items: [
        {
          icon: 'fa-solid fa-shield-halved',
          title: 'producers',
          toRouteName: 'producer_index'
        },
        {
          icon: 'fa-solid fa-calendar-days',
          title: 'events',
          toRouteName: 'event_index'
        },
        {
          icon: 'mdi-barcode-scan',
          title: 'reading',
          toRouteName: 'index'
        },
        {
          icon: 'fa-solid fa-grid-2',
          title: 'sections',
          toRouteName: 'offline_section'
        },
        {
          icon: 'mdi-chart-line',
          title: 'reports',
          toRouteName: 'report'
        },
        {
          icon: 'mdi-math-log',
          title: 'logs',
          toRouteName: 'logs'
        },
        {
          icon: 'mdi-database',
          title: 'cargo',
          items: [
            {
              icon: 'mdi-database-cog',
              title: 'manage',
              toRouteName: 'offline_manage'
            }
          ]
        }
      ]
    }
  }),
  
  methods: {
    //
  },

  computed: {
    event() {
      return this.$store.state.events.selected
    }
  }
}
</script>
<template>
  <layout-default>
    <div
      class="views offline configuration"
    >
      <v-container>
        <h1>
          Configurações
        </h1>
        <br />
        <views-partials-configuration-connection
          :showBackButton="false"
        />
      </v-container>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/Default'
import ViewsPartialsConfigurationConnection from '@/views/partials/ConfigurationConnection'

export default {
  name: 'OfflineConfiguration',

  components: {
    LayoutDefault,
    ViewsPartialsConfigurationConnection
  },
  
  data: () => ({
    //
  }),
  
  methods: {
    //
  }
}
</script>

<style lang="scss">
.views {
  &.offline {
    &.configuration {
      //
    }
  }
}

@media screen and (max-width: 600px) {
  .views {
    &.offline {
      &.configuration {
        //
      }
    }
  }
}
</style>

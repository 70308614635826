import moment from "moment";
import EventService from "../../services/EventService";

const state = {
  data: [],
  finished: [],
  selected: null,
}

const mutations = {
  set(_state, data) {
    _state.data = data;
  },
  select(_state, data) {
    _state.selected = data;
  },
  clear(_state) {
    _state.data = [];
    _state.selected = null;
  },
  unselect(_state) {
    _state.selected = null;
  },
  pushToFinished(_state, { event }) {
    _state.finished.push(event)
  }
}

const actions = {
  async fetch(context) {
    try {
      const client = context.rootState.clients.selected
      const producer = context.rootState.producers.selected
      const events = await EventService.index(client, producer)
      context.commit("set", events)
    } catch (error) {
      context.commit(
        'snackbar/show', 
        {
          message: 'error_reading_clients',
          timeout: 5000,
          color: 'error'
        },
        { root: true }
      )
    }
  },
  async finish(context) {
    const client = context.rootState.clients.selected
    const producer = context.rootState.producers.selected
    const event = context.rootState.events.selected
    const vouchers = context.rootState.vouchers.data[event.id]
    const sections = context.rootState.sectors.selected.map(item => item.id)
    const filtered = vouchers.filter((v) => v.checkin_at !== null);

    if (context.rootState.reading.selected === 'offline') {
      context.dispatch("logging/add", { message: `Exportação iniciada com ${filtered.length} códigos`}, { root: true })
      await EventService.export(client, producer, event, filtered, sections);
      context.dispatch("logging/add", { message: `Exportação finalizada com ${filtered.length} códigos`}, { root: true })
    }

    event.finished_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    event.readings = {
      legal: filtered.length,
      illegal: vouchers.length - filtered.length
    }

    context.commit("pushToFinished", { event })
  }
}

export const store = {
  namespaced: true,
  state,
  mutations,
  actions
}
import Vue from "vue"

export default Vue.observable({
  auth: {
    token: null
  },

  user: null,

  language: null,

  http: {
    loading: 0
  },

  selected: {
    client: {},
    producer: {},
    event: {}
  },

  networkAddress: '',
  
  clients: [],
  clientsProducers: [],
  clientsProducersEvents: [],
  clientsProducersEventsLocal: [],
  clientsProducersEventsVouchersHistory: [],

  module: {
    active: '',
    items: {
      online: {
        routeName: 'online',
        icon: 'fas fa-wifi',
        title: 'online',
        menu: [
          {
            icon: 'mdi-barcode-scan',
            title: 'reading',
            toRouteName: 'online_index'
          },
          {
            icon: 'mdi-view-grid',
            title: 'sections',
            toRouteName: 'online_section'
          }
        ]
      },
      network: {
        routeName: 'network',
        icon: 'fas fa-network-wired',
        title: 'network',
        menu: [
          {
            icon: 'mdi-barcode-scan',
            title: 'reading',
            toRouteName: 'network_index'
          },
          {
            icon: 'mdi-view-grid',
            title: 'sections',
            toRouteName: 'network_section'
          },
          {
            icon: 'mdi-cog',
            title: 'configurations',
            toRouteName: 'network_configuration'
          }
        ]
      },
      offline: {
        routeName: 'offline',
        icon: 'fas fa-wifi-slash',
        title: 'offline',
        menu: [
          {
            icon: 'mdi-barcode-scan',
            title: 'reading',
            toRouteName: 'offline_index'
          },
          {
            icon: 'mdi-view-grid',
            title: 'sections',
            toRouteName: 'offline_section'
          },
          {
            icon: 'mdi-chart-line',
            title: 'reports',
            toRouteName: 'report'
          },
          {
            icon: 'mdi-database',
            title: 'cargo',
            items: [
              {
                icon: 'mdi-database-cog',
                title: 'manage',
                toRouteName: 'offline_manage'
              }
            ]
          }
        ]
      }
    },
    connection: {
      test: {
        network: false,
        online: false
      }
    }
  },
  layout: {}
})

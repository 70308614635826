<template>
  <v-form id="event-selector" class="mt-6" ref="views_event_index_form" v-model="form.valid" lazy-validation>
    <v-row no-gutters class="justify-center">
      <v-col class="window" cols="12">
        <v-select
          v-model="form.fields.event.value"
          outlined
          hide-details="auto"
          item-text="title"
          item-value="id"
          :items="events"
          :label="$t('events_choose')"
          :no-data-text="$t('no_data')"
          :rules="[form.fields.event.rules.required]"
        >
          <template v-slot:message="slotProps">
            {{ $t(slotProps.message) }}
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-col>
                <div>
                  {{ item.title }}
                </div>
                <small>
                  {{ item.type }}
                </small>
              </v-col>
            </v-list-item-content>
          </template>
        </v-select>
      </v-col>
      <v-col class="window mt-6" cols="12">
        <v-btn
          block
          x-large
          color="primary"
          @click="submit()"
        >
          {{ $t("choose") }}
        </v-btn>
        <v-btn
          block
          x-large
          color="secondary"
          @click="$router.go(-1)"
          class="mt-6"
        >
          {{ $t("return") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

export default {
  data() {
    return {
      form: {
        valid: false,
        fields: {
          event: {
            rules: {
              required: value => !! value || 'events_choose'
            }
          }
        }
      },
    }
  },
  computed: {
    events() {
      return this.$store.state.events.data
    },
  },
  methods: {
    async submit() {
      const event = this.events.find(item => String(item.id) === String(this.form.fields.event.value))

      if (! this.$refs.views_event_index_form.validate()) {
        return
      }

      this.$emit('submit', event)
    }
  }
}
</script>

<style lang="scss">
#event-selector {
  .window {
  }
}
</style>
